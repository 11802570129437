import { createContext } from "react";
import { openAxiosInstance, securedAxiosInstance } from "./instances";

const authAPI = {
  login: (id, pass) => {
    return openAxiosInstance.get(`Login.aspx?loginname=${id}&passwd=${pass}`);
  },
  getProfileInfo: (unid) => {
    return openAxiosInstance.get(`getUser.aspx?unid=${unid}`);
  },
  signUp: (
    mobno,
    email,
    add1,
    add2,
    add3,
    compname,
    area,
    city,
    state,
    ref,
    pin,
    transport,
    remark,
    gstin,
    username,
    pass
  ) => {
    return openAxiosInstance.get(
      `RegisterWeb.aspx?mobno=${mobno}&email=${email}&par_add1=${add1}&par_add2=${add2}&par_add3=${add3}&compname=${compname}&area=${area}&city=${city}&state=${state}&ref=${ref}&pin=${pin}&transport=${transport}&remarks=${remark}&gstin=${gstin}&username=${username}&passwd=${pass}`
    );
  },
  forgetPassword: (mobno) => {
    return openAxiosInstance.get(`ForgotPassword.aspx?mobno=${mobno}`);
  },
  updatePassword: (mobno, otp, pass) => {
    return openAxiosInstance.get(
      `UpdatePasswd.aspx?mobno=${mobno}&otp=${otp}&passwd=${pass}`
    );
  },
  updateProfile: (
    unid,
    mobno,
    email,
    add1,
    add2,
    add3,
    compname,
    area,
    city,
    state,
    ref,
    pin,
    transport,
    remark,
    gstin
  ) => {
    return openAxiosInstance.get(
      `UpdateUser.aspx?unid=${unid}&mobno=${mobno}&email=${email}&par_add1=${add1}&par_add2=${add2}&par_add3=${add3}&compname=${compname}&area=${area}&city=${city}&state=${state}&ref=${ref}&pin=${pin}&transport=${transport}&remarks=${remark}&gstin=${gstin}`
    );
  },
  updateProfilePic: (id, file) => {
    const formData = new FormData();
    formData.append("file", file, `${id}.jpg`);
    return openAxiosInstance.post(`updProfilePic.aspx`, formData);
  },
  getStoreList: (unid, par_code) => {
    return openAxiosInstance.get(
      `GetStoreList.aspx?unid=${unid}&par_code=${par_code}`
    );
  },
  updateStore: (unid, par_code, storename, gmaplink, pin) => {
    return openAxiosInstance.get(
      `SaveStoreDetails.aspx?unid=${unid}&par_code=${par_code}&storename=${storename}&gmaplink=${gmaplink}&pin=${pin}`
    );
  },
};

const categoriesAPI = {
  getDataFromDB: (type, ntype, cnd, id) => {
    return openAxiosInstance.get(
      `getMaster.aspx?type=${type}&ntype=${ntype}&cnd=${cnd}&id=${id}`
    );
  },
  getSearchedDataFromDB: (type, cnd, id) => {
    return openAxiosInstance.get(
      `MISCSEARCH.aspx?type=${type}&cnd=${cnd}&UNID=${id}`
    );
  },
  // getSpecialCategorieData: (type, ntype, cnd) => {
  //   return openAxiosInstance.get(
  //     `getMaster.aspx?type=${type}&ntype=${ntype}&cnd=${cnd}`
  //   );
  // },
  getCategoriesData: (ccode, unid) => {
    return openAxiosInstance.get(
      `getCatgSCatg.aspx?ccode=${ccode}&unid=${unid}`
    );
  },
  // getSubCategoryData: (type, ntype, cnd, id) => {
  //   return openAxiosInstance.get(
  //     `getMaster.aspx?type=${type}&ntype=${ntype}&cnd=${cnd}&id=${id}`
  //   );
  // },
  // getSubSubCategoryData: (type, ntype, cnd, id) => {
  //   return openAxiosInstance.get(
  //     `getMaster.aspx?type=${type}&ntype=${ntype}&cnd=${cnd}&id=${id}`
  //   );
  // },
  // getProductListingData: (type, ntype, cnd, id) => {
  //   return openAxiosInstance.get(
  //     `getMaster.aspx?type=${type}&ntype=${ntype}&cnd=${cnd}&id=${id}`
  //   );
  // },
  // getProductData: (type, ntype, cnd, id) => {
  //   return openAxiosInstance.get(
  //     `getMaster.aspx?type=${type}&ntype=${ntype}&cnd=${cnd}&id=${id}`
  //   );
  // },
  searchByPincode: (pincode, offset, rows, prd_code) => {
    return openAxiosInstance.get(
      `GetStores.aspx?pincode=${pincode}&offset=${offset}&rows=${rows}&prd_code=${prd_code}`
    );
  },
};

const cartAPI = {
  addtoCard: (
    unid,
    prd_name,
    remarks,
    quantity,
    rate,
    prd_code,
    prd_unit,
    updrt = 0,
    eancode
  ) => {
    return openAxiosInstance.get(
      `SaveCart.aspx?unid=${unid}&prd_name=${prd_name}&remarks=${remarks}&quantity=${quantity}&rate=${rate}&prd_code=${prd_code}&prd_unit=${prd_unit}&updrt=${updrt}&eancode=${eancode}`
    );
  },
  deleteFromCart: (unid, billno) => {
    return openAxiosInstance.get(
      `DeleteCart.aspx?unid=${unid}&billno=${billno}`
    );
  },
  updateCart: (
    unid,
    prd_name,
    quantity,
    remarks,
    rate,
    prd_code,
    prd_unit,
    billno
  ) => {
    return openAxiosInstance.get(
      `UpdateCart.aspx?unid=${unid}&quantity=${quantity}&remarks=${remarks}&prd_name=${prd_name}&billno=${billno}&prd_unit=${prd_unit}&prd_code=${prd_code}&rate=${rate}`
    );
  },
  getCartDetail: (unid) => {
    return openAxiosInstance.get(`getCartDet.aspx?unid=${unid}`);
  },
  getCartSummary: (unid) => {
    return openAxiosInstance.get(`getCartSumm.aspx?unid=${unid}`);
  },
  saveToCart: (unid) => {
    return openAxiosInstance.get(`SaveOrder.aspx?unid=${unid}`);
  },
};

const bookingSummaryAPI = {
  getBookings: (unid) => {
    return openAxiosInstance.get(`getBooking.aspx?unid=${unid}&option=summary`);
  },
  getBookingsDetails: (unid, date) => {
    return openAxiosInstance.get(
      `getBookdetail.aspx?unid=${unid}&date=${date}`
    );
  },
};

const trackOrdersAPI = {
  trackOrders: (unid) => {
    return openAxiosInstance.get(`getDispatch.aspx?unid=${unid}`);
  },
  trackOrdersDetails: (unid, vchKey) => {
    return openAxiosInstance.get(
      `getDisptachDet.aspx?unid=${unid}&vchkey=${vchKey}`
    );
  },
  reorder: (unid, key, srNo, qty, rmk) => {
    return openAxiosInstance.get(
      `Rebook.aspx?unid=${unid}&rkey=${key}/${srNo}&qty=${qty}&remarks=${rmk}`
    );
  },
};

const customOrderApi = {
  customOrder: (unid, prd_name, rmk, qty, rate) => {
    return openAxiosInstance.get(
      `CustomOrder.aspx?unid=${unid}&prd_name=${prd_name}&remarks=${rmk}&quantity=${qty}&rate=${rate}`
    );
  },
  customOrderImg: (file) => {
    return openAxiosInstance.post(`UploadCustImg.aspx`, file);
  },
};
const feedbackAPI = {
  feedback: (unid, rmk, name, phNo) => {
    return openAxiosInstance.get(
      `SaveFeedback.aspx?unid=${unid}&remarks=${rmk}&name=${name}&phoneNumber=${phNo}`
    );
  },
};

export const api = {
  ...authAPI,
  ...categoriesAPI,
  ...cartAPI,
  ...customOrderApi,
  ...feedbackAPI,
  ...bookingSummaryAPI,
  ...trackOrdersAPI,
};

export const Context = createContext();
