import { createSlice } from "@reduxjs/toolkit";
import { setAuthorizationToken } from "../api/instances";

const initialState = {
  isAuthenticated: false,
  name: "",
  id: "",
  message: "",
  result: "",
  userId: "",
  user:null,
};

export const slice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (state, action) => {
      // const {id , name , message ,result , userid} = action.payload;
      setAuthorizationToken(action.payload.id);
      localStorage.setItem("id", action.payload.id);
      localStorage.setItem("isAuthenticated", true);
      localStorage.setItem("user", JSON.stringify(action.payload));
      state.isAuthenticated = true;
      state.name = action.payload.name;
      state.id = action.payload.id;
      state.message = action.payload.message;
      state.result = action.payload.result;
      state.userId = action.payload.userid;
      state.user = JSON.stringify(action.payload);
    },
    logOut: (state) => {
      localStorage.removeItem("id");
      localStorage.removeItem("isAuthenticated");
      localStorage.removeItem("user");
      state.isAuthenticated = initialState.isAuthenticated;
      state.name = initialState.name;
      state.id = initialState.id;
      state.message = initialState.message;
      state.result = initialState.result;
      state.userId = initialState.userId;
    },
  },
});

export const { login, logOut } = slice.actions;

export default slice.reducer;
