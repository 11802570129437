import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import warningLogo from "../../assets/icons/modal/warning.png";
import close from "../../assets/icons/modal/close.png";

const UnApprovedPopUp = (props) => {
  //   const [open, setOpen] = React.useState(false);
  const handleOpen = props.handleOpen;
  const handleClose = props.handleClose;
  const open = props.open;

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box>
            <Box sx={{position:'absolute' , right:17 , top:17 , cursor:'pointer'}}  onClick = {handleClose}>
              <img src={close} alt="" height={45} />
            </Box>
            <Box display={"flex"} justifyContent={"center"}>
              <img src={warningLogo} alt="" height={70} />
            </Box>
            <Box mt={3} mb={5}>
              <Typography
                id="modal-modal-description"
                // sx={{ mt: 2 }}
                variant="h5"
                color={"#465152"}
                fontWeight={500}
                align="center"
                sx={{
                  fontSize:{xs:"1rem" , sm :"1.5rem"}
                }}
              >
               {props.msg1?props.msg1:""}
              </Typography>
              <Typography
                id="modal-modal-description"
                // sx={{ mt: 2 }}
                variant="h5"
                color={"#465152"}
                fontWeight={500}
                align="center"
                sx={{
                  fontSize:{xs:"1rem" , sm :"1.5rem"}
                }}
              >
                {props.msg2}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default UnApprovedPopUp;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: {xs:'90%',sm:550},
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};
