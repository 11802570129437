import React, { useState } from "react";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import close from "../../assets/icons/modal/close.png";
import TextField from "../CustomComponents/TextField";
import { Grid } from "@mui/material";
import ConfirmationPopUp from "./ConfirmationPopUp";
import TextArea from "../CustomComponents/TextArea";
import { api } from "../../api/api";
import { useSelector } from "react-redux";
import { useQueryClient } from "@tanstack/react-query";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { createPromiseToast } from "../../utils/createPromiseToast";

const EditQuantityModal = ({
  handleClose,
  open,
  productname,
  amountPerPcs,
  amount,
  item,
}) => {
  const { auth } = useSelector((state) => state);
  const queryClient = useQueryClient();

  const [openConfirmationModal, setOpenConfirmationModal] = React.useState(
    false
  );
  const [loading, setLoading] = useState(false);

  const handleUpdateCart = async (values) => {
    // if (loading) return;
    if (values.quantity < item.MOQ) {
      createPromiseToast({
        success: false,
        message: `Quantity cannot be less than ${item.MOQ}`,
      });

      return;
    }
    if (values.quantity % item.MOQ !==0) {
      createPromiseToast({
        success: false,
        message: `Quantity should be in multiples of ${item.MOQ}`,
      });

      return;
    }
    setLoading(true);
    try {
      const response = await api.updateCart(
        auth.id,
        item.PRD_NAME,
        values.quantity,
        values.remarks,
        item.RATE,
        item.PRD_CODE,
        item.UNIT,
        item.BILLNO
      );
      if (response.data[0].result === 1) {
        queryClient.invalidateQueries({
          queryKey: ["cartDetail"],
        });
        queryClient.invalidateQueries({
          queryKey: ["cartSummary"],
        });
        handleClose();
        setOpenConfirmationModal(true);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
    // setLoading(false);
  };

  const handleOpenConfirmationModal = () => setOpenConfirmationModal(true);
  const handleCloseConfirmationModal = () => setOpenConfirmationModal(false);

  const initialValues = {
    quantity: (item && item.QUANTITY) ||'',
    remarks: (item && item.REMARKS) ||'',
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            height={"4rem"}
            p={2}
            mb={1}
          >
            <Typography
              // noWrap
              variant="h5"
              sx={{
                color: "#465152",
                fontWeight: "500",
                width: "fit-content",
              }}
            >
              {productname ? productname : "Product Name"}
            </Typography>
            <Box
              sx={{
                // position: "absolute",
                right: 17,
                top: 17,
                cursor: "pointer",
              }}
              onClick={handleClose}
            >
              <img src={close} alt="" height={45} />
            </Box>
          </Box>
          <hr />
          <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={validate}
            onSubmit={(values) => handleUpdateCart(values)}
          >
            {(formik) => {
              return (
                <Form autoComplete="off" action="">
                  <Box px={{ xs: 3, sm: 7 }} pt={3}>
                    <Box mb={4}>
                      <TextField
                        label="Quantity*"
                        placeholder="Enter Quantity"
                        id="quantity"
                        name="quantity"
                        type="number"
                        value={formik.values.quantity}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        // onChange={(e) => setNewQuantity(e.target.value)}
                      />
                    </Box>
                    <Box mt={2}>
                      <TextArea
                        label="Remark"
                        placeholder="Enter Remark"
                        id="remark"
                        name="remarks"
                        type="text"
                        rows={6}
                        value={formik.values.remarks}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        // onChange={(e) => setNewRemark(e.target.value)}
                      />
                    </Box>
                    <Box sx={{ paddingLeft: "15px" }} mt={2}>
                      <Typography
                        variant="body1"
                        sx={{
                          color: "#465152",
                          fontWeight: "500",
                          width: "fit-content",
                        }}
                      >
                        Quantity : {item.QUANTITY} pcs @ Rs. {amountPerPcs}
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{
                          color: "#465152",
                          fontWeight: "500",
                          width: "fit-content",
                        }}
                      >
                        Amount : {amount}
                      </Typography>
                    </Box>
                    <Grid
                      container
                      spacing={2}
                      display={"flex"}
                      justifyContent={"space-between"}
                      my={3}
                    >
                      <Grid xs={6} item>
                        <Button
                          onClick={() => handleClose()}
                          sx={{
                            borderRadius: 50,
                            width: "100%",
                            bgcolor: "#D9E7F5",
                            border: "none",
                            textTransform: "none",
                            height: "45px",
                            fontSize: "15px",
                            color: "#0281C6",
                            "&:hover": {
                              bgcolor: "#D9E7F5",
                              color: "#0281C6",
                            },
                          }}
                        >
                          Cancel
                        </Button>
                      </Grid>
                      <Grid xs={6} item>
                        <Button
                          type="submit"
                          // onClick={() => {
                          //   handleOpenConfirmationModal();
                          //   handleClose();
                          //   // handleUpdateCart();
                          // }}
                          sx={{
                            borderRadius: 50,
                            width: "100%",
                            bgcolor: "#0281C6",
                            border: "none",
                            textTransform: "none",
                            height: "45px",
                            fontSize: "15px",
                            color: "#FFF",
                            "&:hover": {
                              bgcolor: "#0281C6",
                              color: "#FFFFFF",
                            },
                          }}
                        >
                          {loading ? (
                            <CircularProgress
                              style={{
                                height: "100% !important",
                                width: "auto !important",
                                aspectRatio: "1 !important",
                              }}
                              sx={{
                                color: "orange",
                                height: "80%",
                                aspectRatio: 1,
                                width: "auto",
                              }}
                            />
                          ) : (
                            "Submit"
                          )}
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </Form>
              );
            }}
          </Formik>
        </Box>
      </Modal>
      <ConfirmationPopUp
        open={openConfirmationModal}
        handleOpen={handleOpenConfirmationModal}
        handleClose={handleCloseConfirmationModal}
        msg={"Your Order has been edited successfully"}
        nextPath={"/cart"}
      />
    </div>
  );
};

export default EditQuantityModal;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "90%", sm: 550 },
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 1,
  borderRadius: 2,
  border: "none",
  outline: "none",
};



const validate = Yup.object({
  quantity: Yup.string().required("Quantity is required"),
});
