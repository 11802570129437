import React from "react";
import { Outlet } from "react-router-dom";
import DrawerAppBar from "./Navbar";
import Footer from "./Footer";
import Box from '@mui/material/Box';
import NavbarLinks from './NavbarLInks'

const OutletComponent = () => {
  return (
    <div>
      <DrawerAppBar />
      {/* <NavbarLinks/> */}
      {/* <Box  > */}
        <Outlet/>
      {/* </Box> */}
      <Footer />
    </div>
  );
};

export default OutletComponent;
