import React from "react";
import { styled } from "@mui/material/styles";
import MuiButton from "@mui/material/Button";
import {  TextField } from "@mui/material";

export const CustomStyle = () => {
  return <div>CustomStyle</div>;
};

export const Button = styled(MuiButton)(({ }) => ({
  borderRadius:50,
  paddingLeft:30,
  paddingRight:30,
  backgroundColor:'#FFFFFF',
  border:'none',
  textTransform: 'none',
  // color: color =="" ?"":"#FFF",
  '&:hover': {
    background: "#0281C6",
    color:'#FFFFFF',
    border: 'none',
 },
}));

export const CustomSearchField = styled(TextField)(() => ({
    '& fieldset': {
        border: 'none', // Remove the border
      },
      '&:hover fieldset': {
        border: 'none', // Remove the border on hover
      },
      '&.Mui-focused fieldset': {
        border: 'none', // Remove the border when focused
      },
}));

