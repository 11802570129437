import React from "react";
import IconButton from "@mui/material/IconButton";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";

const CustomArrows = ({
  onClick,
  direction,
  className,disabled
}) => {
  let position = direction === "right" ? { right: 0 } : { left: 0 };

  return (
    <IconButton
      onClick={onClick}
      className={className}
      sx={{
        position: "absolute",
        top: "50%",
        backgroundColor: "#D9E7F5",
        transform:
          direction === "right" ? "translate(130%,0%)" : "translate(-130%,-0%)",
        ...position,
        "&:hover": {
          backgroundColor: "#0281C6",
        },
        display:{lg:'flex',xs:'none'},
  
        "&.Mui-disabled": {
          background: "#9AA0A1",
        }
      }}
      disabled={disabled}
    >
      {direction === "right" ? (
        <ArrowForwardIosRoundedIcon
          sx={{
            fontSize: "3rem",
            padding: ".8rem",
            color: disabled?'black':"#0281C6",
            "&:hover": {
              color: "white",
            },
          }}
        />
      ) : (
        <ArrowBackIosNewRoundedIcon
          sx={{
            fontSize: "3rem",
            padding: ".8rem",
            color: disabled?'grey':"#0281C6",
            "&:hover": {
              color: "white",
            },
          }}
        />
      )}
    </IconButton>
  );
};

export default CustomArrows;
