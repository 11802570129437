import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import confirmLogo from "../../assets/icons/modal/confirm.png";
import close from "../../assets/icons/modal/close.png";
import { useLocation, useNavigate } from "react-router-dom";

const ConfirmationPopUp = ({
  handleOpen,
  handleClose,
  open,
  msg,
  nextPath,
  btnText,
}) => {

    const navigate  = useNavigate();
    const location  = useLocation();
    // setTimeout(()=>{
    //   handleClose();
    // },1000)

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box>
            {nextPath ? null : (
              <Box
                sx={{
                  position: "absolute",
                  right: 17,
                  top: 17,
                  cursor: "pointer",
                  height:{xs:'45px', sm:'60px'}
                }}
                onClick={handleClose}
              >
                <img src={close} alt="" height={'100%'} />
              </Box>
            )}
            <Box display={"flex"} justifyContent={"center"} height={{xs:'45px', sm:'70px'}}>
              <img src={confirmLogo} alt="" height={'100%'} />
            </Box>
            <Box mt={{xs:1,sm:3}} mb={{xs:2,sm:5}} display={'flex'} justifyContent={'center'}>
              <Typography
                id="modal-modal-description"
                // variant={"h5"}
                color={"#465152"}
                fontWeight={500}
                align="center"
                width={!nextPath?'70%':'100%'}
                sx={{
                  fontSize:{xs:'1rem',sm:'1.5rem'}
                }}
              >
                {msg}
              </Typography>
            </Box>
            {nextPath?
            <Box display={'flex'} justifyContent={'center'}>
              <Button
                onClick={()=> {
                  if(location.pathname == nextPath){
                    handleClose();
                  }else
                  navigate(nextPath)}}
                sx={{
                  borderRadius: 50,
                  width: "50%",
                  bgcolor: "#0281C6",
                  border: "none",
                  textTransform: "none",
                  height: "45px",
                  fontSize: "15px",
                  color: "#FFF",
                  transform:{xs:"scale(.8)",sm:'scale(1)'},
                  "&:hover": {
                    bgcolor: "#0281C6",
                    color: "#FFFFFF",
                  },
                }}
              >
                {btnText?btnText:"Continue"}
              </Button>
            </Box>:null}
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default ConfirmationPopUp;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: {xs:'90%',sm:550},
  bgcolor: "background.paper",
  boxShadow: 24,
  p: {xs:2,sm:4},
  borderRadius: 2,
};
