import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import confirmLogo from "../../assets/icons/modal/confirm.png";
import close from "../../assets/icons/modal/close.png";
import { useNavigate } from "react-router-dom";
import TextField from "../CustomComponents/TextField";
import { Grid } from "@mui/material";
import ConfirmationPopUp from "./ConfirmationPopUp";
import TextArea from "../CustomComponents/TextArea";
import { FormGroup } from "semantic-ui-react";
import * as Yup from "yup";
import { ErrorMessage, Form, Formik } from "formik";
import { api } from "../../api/api";
import { useSelector } from "react-redux";
import { createPromiseToast } from "../../utils/createPromiseToast";
import { CircularProgress } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";

const ReorderModal = ({
  handleOpen,
  handleClose,
  open,
  productname,
  amountPerPcs,
  params,
  srNo,
  item
}) => {
  const navigate = useNavigate();
  const { auth } = useSelector((state) => state);
  const [openConfirmationModal, setOpenConfirmationModal] = React.useState(
    false
  );
  const [loading, setLoading] = React.useState(false);
  const handleOpenConfirmationModal = () => setOpenConfirmationModal(true);
  const handleCloseConfirmationModal = () => setOpenConfirmationModal(false);
  const queryClient = useQueryClient();

  const initial = {
    quantity: item && item.QUANTITY ||'',
    remark: item && item.REMARKS ||'',
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    await api
      .reorder(auth.id, params, srNo, values.quantity, values.remark)
      .then((res) => {
        if (res.data[0].result === 2) {
          setLoading(false);
          return {
            success: true,
            message: "Item already added to the cart",
          };
        }
        if(res.data[0].result === 1){
          handleOpenConfirmationModal();
          handleClose();
          queryClient.invalidateQueries({
            queryKey: ["cartDetail"],
          });
          queryClient.invalidateQueries({
            queryKey: ["cartSummary"],
          });
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        return {
          success: false,
          message: "Something went wrong",
        };
      })
      .then((td) => {
        createPromiseToast(td);
      });
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            height={"4rem"}
            p={2}
            mb={1}
          >
            <Typography
              // noWrap
              variant="h5"
              sx={{
                color: "#465152",
                fontWeight: "500",
                width: "fit-content",
                fontSize: { xs: "1rem", sm: "1.5rem" },
              }}
            >
              {productname ? productname : "Product Name"}
            </Typography>
            <Box
              sx={{
                position: "absolute",
                right: 17,
                top: 17,
                cursor: "pointer",
              }}
              onClick={handleClose}
            >
              <img src={close} alt="" height={45} />
            </Box>
          </Box>
          <hr />
          <Formik
            initialValues={initial}
            enableReinitialize
            validationSchema={validate}
            onSubmit={(values) => handleSubmit(values)}
          >
            {(formik) => {
              return (
                <Form autoComplete="off" action="">
                  <Box px={{ xs: 3, sm: 7 }} pt={3}>
                    <Box mb={4} width={"100%"}>
                      <FormGroup>
                        <Typography
                          variant="body1"
                          sx={{
                            color: "#465152",
                            marginBottom: "4px",
                            paddingLeft: "15px",
                          }}
                        >
                          Quantity*
                        </Typography>
                        <input
                          className="input-textfield"
                          placeholder="Enter Quantity"
                          name="quantity"
                          type="number"
                          style={{ width: "100%" }}
                          value={formik.values.quantity}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          step="0"
                          min={1}
                        />
                        <ErrorMessage
                          component="div"
                          name="quantity"
                          className="error-message"
                        />
                      </FormGroup>
                    </Box>
                    <Box mt={2}>
                      <FormGroup>
                        <Typography
                          variant="body1"
                          sx={{
                            color: "#465152",
                            marginBottom: "4px",
                            paddingLeft: "15px",
                          }}
                        >
                          Remark
                        </Typography>
                        <textarea
                          className="text-area"
                          placeholder="Enter Remark"
                          name="remark"
                          type="text"
                          rows={6}
                          style={{ width: "100%" }}
                          value={formik.values.remark}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                      </FormGroup>
                    </Box>
                    <Box sx={{ paddingLeft: "15px" }} mt={2}>
                      <Typography
                        variant="body1"
                        sx={{
                          color: "#465152",
                          fontWeight: "500",
                          width: "fit-content",
                        }}
                      >
                        Quantity : 1 pcs @ Rs. {amountPerPcs}
                      </Typography>
                    </Box>
                    <Grid
                      container
                      spacing={2}
                      display={"flex"}
                      justifyContent={"space-between"}
                      my={3}
                    >
                      <Grid xs={6} item>
                        <Button
                          onClick={() => handleClose()}
                          sx={{
                            borderRadius: 50,
                            width: "100%",
                            bgcolor: "#F5D9D9",
                            border: "none",
                            textTransform: "none",
                            height: "45px",
                            fontSize: "15px",
                            color: "#C60202",
                            "&:hover": {
                              bgcolor: "#F5D9D9",
                              color: "#C60202",
                            },
                          }}
                        >
                          Close
                        </Button>
                      </Grid>
                      <Grid xs={6} item>
                        <Button
                          type="submit"
                          sx={{
                            borderRadius: 50,
                            width: "100%",
                            bgcolor: "#0281C6",
                            border: "none",
                            textTransform: "none",
                            height: "45px",
                            fontSize: "15px",
                            color: "#FFF",
                            "&:hover": {
                              bgcolor: "#0281C6",
                              color: "#FFFFFF",
                            },
                          }}
                        >
                          {loading ? (
                            <CircularProgress
                              style={{
                                height: "100% !important",
                                width: "auto !important",
                                aspectRatio: "1 !important",
                              }}
                              sx={{
                                color: "orange",
                                height: "80%",
                                aspectRatio: 1,
                                width: "auto",
                              }}
                            />
                          ) : (
                            "Rebook Order"
                          )}
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </Form>
              );
            }}
          </Formik>
        </Box>
      </Modal>
      <ConfirmationPopUp
        open={openConfirmationModal}
        handleOpen={handleOpenConfirmationModal}
        handleClose={handleCloseConfirmationModal}
        msg={"Your Order has been added successfully"}
        // nextPath={"/login"}
      />
    </div>
  );
};

export default ReorderModal;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "90%", sm: 550 },
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 1,
  borderRadius: 2,
  border: "none",
  outline: "none",
};



const validate = Yup.object({
  quantity: Yup.number().required("Quantity is required").min(1,"Minimum quantity is required"),
});
