import { configureStore , getDefaultMiddleware} from "@reduxjs/toolkit";
import authReducer from "./auth.slice"
import productReducer from './productSlice'
import cartSlice from "./cart.slice"
import profileReducer from './profile.slice'

export const store = configureStore({
    reducer: {
      auth:authReducer,
      product:productReducer,
      cart: cartSlice,
      profile:profileReducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
  });