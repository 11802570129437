import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    totalItems: 0,
};


export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    setTotalItems:(state,action)=>{
        state.totalItems=action.payload
    },
  },
});

// Action creators are generated for each case reducer function
export const {setTotalItems} = cartSlice.actions;

export default cartSlice.reducer;
