import React, { useState } from "react";
import {
  Box,
  Link,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { AppBar, Toolbar, Menu, MenuItem } from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { useSelector } from "react-redux";


const NavbarLinks = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [value, setValue] = React.useState(0);
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState(null);
  
  const { isAuthenticated, name } = useSelector((state) => state.auth);

  const handleDropdownClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const linkSX = {
    textDecoration: "none",
    fontWeight: "500",
    display: "flex",
    placeItems: "center",
    gap: ".3rem",
  };
  const dropDownSX = {
    textDecoration: "none",
    fontWeight: "400",
    fontSize: "14px",
  };
  const bottomDropDownSX = {
    textDecoration: "none",
    fontWeight: "400",
    fontSize: "10px",
  };
  const menuItemSX = { py: ".15rem", px: ".9rem" };
  const bottomMenItemSX = { py: ".15rem", px: ".9rem", minHeight: "auto" };
  const bottomLinkSX = {
    fontSize: "10px",
    padding: 0,
    color: "#465152",
    minWidth: "auto",
    "& .MuiBottomNavigationAction-label": {
      fontSize: "10px",
    },
  };

  const handleDropdownClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{
      flexGrow: 1,
      top: 0, zIndex: 500, position: "static"
    }}>
      {isSmallScreen ? (""
      ) : (
        // Render a horizontal navigation bar for larger screens
        <AppBar position="static" elevation={0}>
          <Toolbar
            sx={{
              backgroundColor: "#FFF",
              display: "flex",
              gap: { sm: "2rem", md: "4rem" },
              justifyContent: "center",
              color: "#465152",
              py: 3,
            }}
          >
            <Link component={RouterLink} to="/" color={`${location.pathname === '/' ? "#0281C6" : "inherit"}`} sx={linkSX}>
              Home
            </Link>
            <div>
              <Link
                component={RouterLink}
                // to="/product"
                color="inherit"
                onClick={handleDropdownClick}
                sx={linkSX}
              >
                Products {anchorEl ? <ExpandLess /> : <ExpandMore />}
              </Link>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleDropdownClose}
                sx={{ mt: ".4rem" }}
                elevation={0}
              >
                <MenuItem onClick={handleDropdownClose} sx={menuItemSX}>
                  <Link
                    component={RouterLink}
                    to="/2983-Art & Craft"
                    color="inherit"
                    sx={dropDownSX}
                  >
                    Art & Craft
                  </Link>
                </MenuItem>
                <MenuItem onClick={handleDropdownClose} sx={menuItemSX}>
                  <Link
                    component={RouterLink}
                    to="/2984-Corporate & Office Stationery"
                    color="inherit"
                    sx={dropDownSX}
                  >
                    Corporate & Office Stationery
                  </Link>
                </MenuItem>
                <MenuItem onClick={handleDropdownClose} sx={menuItemSX}>
                  <Link
                    component={RouterLink}
                    to="/2985-Return Gift & More"
                    color="inherit"
                    sx={dropDownSX}
                  >
                    Return Gift & More
                  </Link>
                </MenuItem>
                <MenuItem onClick={handleDropdownClose} sx={menuItemSX}>
                  <Link
                    component={RouterLink}
                    to="/4404-Special Edition "
                    color="inherit"
                    sx={dropDownSX}
                  >
                    Special Edition
                  </Link>
                </MenuItem>
              </Menu>
            </div>
            <Link
              component={RouterLink}
              to="/newarrival"
              color={`${location.pathname === '/newarrival' ? "#0281C6" : "inherit"}`}
              sx={linkSX}
            >
              New Arrival
            </Link>
            <Link
              component={RouterLink}
              to="/about"
              color={`${location.pathname === '/about' ? "#0281C6" : "inherit"}`}
              sx={linkSX}
            >
              About Us
            </Link>
            <Link
              component={RouterLink}
              to="/contact"
              color={`${location.pathname === '/contact' ? "#0281C6" : "inherit"}`}
              sx={linkSX}
            >
              Contact Us
            </Link>
            {isAuthenticated &&
            <Link
              component={RouterLink}
              to="/bulk-order"
              color={`${location.pathname === '/bulk-order' ? "#0281C6" : "inherit"}`}
              sx={linkSX}
            >
              Bulk Order
            </Link>}
          </Toolbar>
        </AppBar>
      )}
    </Box>
  );
};

export default NavbarLinks;
