import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import confirmLogo from "../../assets/icons/modal/confirm.png";
import close from "../../assets/icons/modal/close.png";
import { useNavigate } from "react-router-dom";
import TextField from "../CustomComponents/TextField";
import { CircularProgress, FormGroup, Grid } from "@mui/material";
import EnterOTP from "./EnterOTP";
import { ErrorMessage, Form, Formik } from "formik";
import * as Yup from "yup";
import { api } from "../../api/api";
import { createPromiseToast } from "../../utils/createPromiseToast";

const ForgotPassword = ({ handleOpen, handleClose, open }) => {
  const [openOTPModal, setOpenOTPModal] = React.useState(false);
  const handleOpenOTPModal = () => setOpenOTPModal(true);
  const handleCloseOTPModal = () => setOpenOTPModal(false);
  const [mobNo, setMobno] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const handleSubmit = async (values) => {
    setLoading(true);
    await api
      .forgetPassword(values.mobileNumber)
      .then((res) => {
        if (res.data[0].result === 1) {
          handleOpenOTPModal();
          handleClose();
          setMobno(values.mobileNumber);
          setLoading(false);
          return {
            success: true,
            message:
              "Please enter the otp sent to your registered mobile number for verification.",
          };
        } else {
          setLoading(false);
          return {
            success: false,
            message: "User not found",
          };
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        return {
          success: false,
          message: "Something went wrong.",
        };
      })
      .then((toastData) => {
        createPromiseToast(toastData);
      });
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            height={"4rem"}
            p={2}
            mb={1}
          >
            <Typography
              // noWrap
              variant="h5"
              sx={{
                color: "#465152",
                fontWeight: "500",
                width: "fit-content",
              }}
            >
              Forgot Password
            </Typography>
            <Box
              sx={{
                position: "absolute",
                right: 17,
                top: 17,
                cursor: "pointer",
              }}
              onClick={handleClose}
            >
              <img src={close} alt="" height={45} />
            </Box>
          </Box>
          <hr />
          <Formik
            initialValues={initialValue}
            enableReinitialize
            validationSchema={validateSchema}
            onSubmit={(values) => handleSubmit(values)}
          >
            {(formik) => {
              return (
                <Form autoComplete="off" action="">
                  <Box px={{ xs: 3, sm: 7 }} pt={3}>
                    <FormGroup>
                      <Typography
                        variant="body1"
                        sx={{
                          color: "#465152",
                          marginBottom: "4px",
                          paddingLeft: "15px",
                        }}
                      >
                        Mobile Number*
                      </Typography>
                      <input
                        className="input-textfield"
                        placeholder="Enter Mobile Number"
                        name="mobileNumber"
                        type="number"
                        step="0"
                        value={formik.values.mobileNumber}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        onWheel={(e) => e.target.blur()}
                      />
                      <ErrorMessage
                        component="div"
                        name="mobileNumber"
                        className="error-message"
                      />
                    </FormGroup>
                    <Grid
                      container
                      spacing={2}
                      display={"flex"}
                      justifyContent={"space-between"}
                      my={3}
                    >
                      <Grid xs={6} item>
                        <Button
                          onClick={() => handleClose()}
                          sx={{
                            borderRadius: 50,
                            width: "100%",
                            bgcolor: "#D9E7F5",
                            border: "none",
                            textTransform: "none",
                            height: "45px",
                            fontSize: "15px",
                            color: "#0281C6",
                            "&:hover": {
                              bgcolor: "#D9E7F5",
                              color: "#0281C6",
                            },
                          }}
                        >
                          Cancel
                        </Button>
                      </Grid>
                      <Grid xs={6} item>
                        <Button
                          type="submit"
                          sx={{
                            borderRadius: 50,
                            width: "100%",
                            bgcolor: "#0281C6",
                            border: "none",
                            textTransform: "none",
                            height: "45px",
                            fontSize: "15px",
                            color: "#FFF",
                            "&:hover": {
                              bgcolor: "#0281C6",
                              color: "#FFFFFF",
                            },
                          }}
                        >
                          {loading ? (
                            <CircularProgress
                              style={{
                                height: "100% !important",
                                width: "auto !important",
                                aspectRatio: "1 !important",
                              }}
                              sx={{
                                color: "orange",
                                height: "80%",
                                aspectRatio: 1,
                                width: "auto",
                              }}
                            />
                          ) : (
                            "Submit"
                          )}
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </Form>
              );
            }}
          </Formik>
        </Box>
      </Modal>
      <EnterOTP
        open={openOTPModal}
        handleOpen={handleOpenOTPModal}
        handleClose={handleCloseOTPModal}
        mobNo={mobNo}
      />
    </div>
  );
};

export default ForgotPassword;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "90%", sm: 550 },
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 1,
  borderRadius: 2,
  border: "none",
  outline: "none",
};

const initialValue = {
  mobileNumber: "",
};
const validateSchema = Yup.object({
  mobileNumber: Yup.string()
    .required("Mobile number is required")
    .min(10, "Mobile number must be at least 10 digits"),
});
