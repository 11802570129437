import * as Yup from "yup";

export const loginInitialValues = {
  mobileNumber: "",
  password: "",
};

export const loginValidateSchema = Yup.object({
  mobileNumber: Yup.string()
    .required("Mobile number is required")
    .min(10, "Mobile number must be at least 10 digits")
    .max(10, "Mobile number must be at max 10 digits")
    .matches(/^[^\s].*$/, "Blank spaces at the start are not allowed"),
  password: Yup.string()
    .required("Enter your password")
    .min(4, "Password must be at least 4 characters")
    .matches(/^[^\s].*$/, "Blank spaces at the start are not allowed"),
});
