import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import confirmLogo from "../../assets/icons/modal/confirm.png";
import close from "../../assets/icons/modal/close.png";
import { useNavigate } from "react-router-dom";
import TextField from "../CustomComponents/TextField";
import { CircularProgress, FormGroup, Grid } from "@mui/material";
import ConfirmationPopUp from "./ConfirmationPopUp";
import { ErrorMessage, Form, Formik } from "formik";
import * as Yup from "yup";
import { api } from "../../api/api";
import { createPromiseToast } from "../../utils/createPromiseToast";

const EnterOTP = ({ handleOpen, handleClose, open , mobNo}) => {
  const navigate = useNavigate();
  const [openConfirmationModal, setOpenConfirmationModal] = React.useState(
    false
  );
  const [loading, setLoading] = React.useState(false);
  const handleOpenConfirmationModal = () => setOpenConfirmationModal(true);
  const handleCloseConfirmationModal = () => setOpenConfirmationModal(false);

  const handleSubmit = async (values) => {
    setLoading(true);
    api.updatePassword(mobNo, values.otp, values.password).then(res=>{
      if(res.data[0].result===1){
        handleOpenConfirmationModal();
        handleClose();
        setLoading(false);
        return {
          success:true,
          message:"Password updated successfully"
        }
      }
      if(res.data[0].result===2){
        setLoading(false);
        return {
          success:false,
          message:"Please check your OTP again"
        }
      }
    }).catch(err=>{
      console.log(err);
      setLoading(false);
      return {
        success: false,
        message:"Something went wrong. Please try again"
      }
    }).then(td=>{
      createPromiseToast(td);
    })
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            height={"4rem"}
            p={2}
            mb={1}
          >
            <Typography
              // noWrap
              variant="h5"
              sx={{
                color: "#465152",
                fontWeight: "500",
                width: "fit-content",
              }}
            >
              Forgot Password
            </Typography>
            <Box
              sx={{
                position: "absolute",
                right: 17,
                top: 17,
                cursor: "pointer",
              }}
              onClick={handleClose}
            >
              <img src={close} alt="" height={45} />
            </Box>
          </Box>
          <hr />
          <Formik
            initialValues={initialValue}
            enableReinitialize
            validationSchema={validateSchema}
            onSubmit={(values) => handleSubmit(values)}
          >
            {(formik) => {
              return (
                <Form autoComplete="off" action="">
                  <Box px={{ xs: 3, sm: 7 }} pt={3}>
                    <Box mb={4}>
                      <FormGroup>
                        <Typography
                          variant="body1"
                          sx={{
                            color: "#465152",
                            marginBottom: "4px",
                            paddingLeft: "15px",
                          }}
                        >
                          Enter OTP*
                        </Typography>
                        <input
                          className="input-textfield"
                          placeholder="Enter OTP"
                          name="otp"
                          type="text"
                          step="0"
                          value={formik.values.otp}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          onWheel={(e) => e.target.blur()}
                        />
                        <ErrorMessage
                          component="div"
                          name="otp"
                          className="error-message"
                        />
                      </FormGroup>
                    </Box>
                    <Box mt={2}>
                      <FormGroup>
                        <Typography
                          variant="body1"
                          sx={{
                            color: "#465152",
                            marginBottom: "4px",
                            paddingLeft: "15px",
                          }}
                        >
                          New Password*
                        </Typography>
                        <input
                          className="input-textfield"
                          placeholder="Enter Password"
                          name="password"
                          type="password"
                          step="0"
                          value={formik.values.password}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          onWheel={(e) => e.target.blur()}
                        />
                        <ErrorMessage
                          component="div"
                          name="password"
                          className="error-message"
                        />
                      </FormGroup>
                    </Box>
                    <Grid
                      container
                      spacing={2}
                      display={"flex"}
                      justifyContent={"space-between"}
                      my={3}
                    >
                      <Grid xs={6} item>
                        <Button
                          onClick={() => handleClose()}
                          sx={{
                            borderRadius: 50,
                            width: "100%",
                            bgcolor: "#D9E7F5",
                            border: "none",
                            textTransform: "none",
                            height: "45px",
                            fontSize: "15px",
                            color: "#0281C6",
                            "&:hover": {
                              bgcolor: "#D9E7F5",
                              color: "#0281C6",
                            },
                          }}
                        >
                          Cancel
                        </Button>
                      </Grid>
                      <Grid xs={6} item>
                        <Button
                          type="submit"
                          sx={{
                            borderRadius: 50,
                            width: "100%",
                            bgcolor: "#0281C6",
                            border: "none",
                            textTransform: "none",
                            height: "45px",
                            fontSize: "15px",
                            color: "#FFF",
                            "&:hover": {
                              bgcolor: "#0281C6",
                              color: "#FFFFFF",
                            },
                          }}
                        >
                          {loading ? (
                            <CircularProgress
                              style={{
                                height: "100% !important",
                                width: "auto !important",
                                aspectRatio: "1 !important",
                              }}
                              sx={{
                                color: "orange",
                                height: "80%",
                                aspectRatio: 1,
                                width: "auto",
                              }}
                            />
                          ) : (
                            "Submit"
                          )}
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </Form>
              );
            }}
          </Formik>
        </Box>
      </Modal>
      <ConfirmationPopUp
        open={openConfirmationModal}
        handleOpen={handleOpenConfirmationModal}
        handleClose={handleCloseConfirmationModal}
        msg={"Your Password has been changes successfully"}
        nextPath={"/login"}
      />
    </div>
  );
};

export default EnterOTP;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "90%", sm: 550 },
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 1,
  borderRadius: 2,
  border: "none",
  outline: "none",
};

const initialValue = {
  otp: "",
  password: "",
};
const validateSchema = Yup.object({
  otp: Yup.string().required("OTP is required").matches(/^[A-Z0-9]+$/, 'Must contain only capital letters'),
  password: Yup.string()
    .required("Password is required")
    .min(4, "Password must be at least 4 characters"),
});
