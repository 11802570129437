import React, { useState } from "react";
import { Box, Button, Grid, Typography, Snackbar, CircularProgress, InputAdornment } from "@mui/material";
import TextField from "../../components/CustomComponents/TextField";
import loginBanner from "../../assets/loginBanner.png";
import { Form, Formik } from "formik";
import { loginInitialValues, loginValidateSchema } from "./Utils";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../store/auth.slice";
import { api } from "../../api/api";
import Swal from "sweetalert2";
import SweetAlert2 from "react-sweetalert2";
import ConfirmationPopUp from "../../components/Modals/ConfirmationPopUp";
import ForgotPassword from "../../components/Modals/ForgotPassword";
import EnterOTP from "../../components/Modals/EnterOTP";
import ReorderModal from "../../components/Modals/ReorderModal";
import EditQuantityModal from "../../components/Modals/EditQuantityModal";
import { Link, useLocation, useNavigate } from "react-router-dom";
import UnApprovedPopUp from "../../components/Modals/UnApprovedPopUp";
import MuiAlert from "@mui/material/Alert";
import { createPromiseToast } from "../../utils/createPromiseToast";
import SearchIcon from "@mui/icons-material/Search";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location  = useLocation();

  const { auth } = useSelector((state) => state);
  const [open, setOpen] = React.useState(false);
  const [openUnApp, setOpenUnApp] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleOpenUnApp = () => setOpenUnApp(true);
  const handleCloseUnApp = () => setOpenUnApp(false);

  const from  = location.state && location.state.from && location.state.from.pathname || '/';

  React.useEffect(() => {
    if (auth.isAuthenticated) {
      navigate(from);
    }
  }, [auth.isAuthenticated]);


  const handleSubmit = async (values) => {
    setLoading(true);
    await api
      .login(values.mobileNumber, values.password)
      .then((res) => {
        if (res.data[0].result === 100) {
          setLoading(false);
          return {
            success: false,
            message: "Please check your password",
          };
        }
        if(res.data[0].result === 0) {
          handleOpenUnApp();
          setLoading(false);
          return {
            success: false,
            message:"You are not yet approved",
          }
        }

        const user = res.data[0];
        if(res.data[0].result === 1) {
        dispatch(login(user));
        navigate(from,{replace: true});
        setLoading(false);
        return {
          success: true,
          message: "Logged in successfully",
        };
      }
      setLoading(false);
      return {
        success: false,
        message: "User is not registered",
      };
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        return {
          success:false,
          message:"Something went wrong"
        }
      })
      .then((toastData) => {
        createPromiseToast(toastData);
      });
  };

  return (
    <Box
      sx={{
        bgcolor: "#D9E7F5",
        backgroundImage: `url(${loginBanner})`,
        // height: { xs: "80dvh", md: "90dvh" },
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Typography
        variant="h3"
        component="div"
        sx={{
          color: "#465152",
          fontWeight: 700,
          marginY: 3,
          
        }}
      >
        Login
      </Typography>
      <Box
        mb={6}
        p={{ xs: 2, md: 6 }}
        px={{ xs: 1, md: 5 }}
        sx={{
          bgcolor: "#fff",
          width: { xs: "85%", md: "60%", lg: "40%" },
          borderRadius: 3,
          boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.08)",
        }}
      >
        <Grid px={{xs:1,sm:6}}>
          <Formik
            initialValues={loginInitialValues}
            enableReinitialize
            validationSchema={loginValidateSchema}
            onSubmit={(values) => handleSubmit(values)}
          >
            {(formik) => {
              return (
                <Form autoComplete="off" action="">
                  <Grid my={2}>
                    <TextField
                      label="Mobile Number*"
                      placeholder="Enter Mobile Number"
                      // id="mobileNumber"
                      name="mobileNumber"
                      type="number"
                      value={formik.values.mobileNumber}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      onWheel={(e) => e.target.blur()}
                      // step="0"
                    />
                  </Grid>
                  <Grid my={3}>
                    <TextField
                      label="Password*"
                      placeholder="Enter Password"
                      id="password"
                      name="password"
                      type="password"
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </Grid>
                  <Grid my={3}>
                    <Button
                      type="submit"
                      disabled={loading}
                      sx={{
                        borderRadius: 50,
                        width: "100%",
                        bgcolor: "#0281C6",
                        border: "none",
                        textTransform: "none",
                        height: "45px",
                        fontSize: "15px",
                        color: "#FFF",
                        "&:hover": {
                          bgcolor: "#0281C6",
                          color: "#FFFFFF",
                        },
                      }}
                    >
                     {loading? (
                      <CircularProgress
                      style={{
                        height: "100% !important",
                        width: "auto !important",
                        aspectRatio: "1 !important",
                      }}
                      sx={{
                        color: "orange",
                        height: "80%",
                        aspectRatio: 1,
                        width: "auto",
                      }}
                    />
                     ):"Login"}
                    </Button>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
          <Grid
            sx={{
              justifyContent: "center",
              display: "flex",
            }}
          >
            <Typography
              my={1}
              onClick={() => handleOpen()}
              gutterBottom={true}
              sx={{
                color: "#465152",
                cursor: "pointer",
                fontWeight: "500",
                borderBottom: "1px solid",
                borderBottomWidth: "1px",
                width: "fit-content",
                "&:hover": {
                  color: "#EE7F1C",
                },
              }}
            >
              Forgot your password?
            </Typography>
          </Grid>
          <Grid
            sx={{
              justifyContent: "center",
              display: "flex",
            }}
          >
            {/* <Link to={'/sign-up'}> */}
            <Typography
              // noWrap
              onClick={() =>  navigate('/signup')}
              sx={{
                color: "#465152",
                cursor: "pointer",
                fontWeight: "500",
                borderBottom: "1px solid",
                borderBottomWidth: "1px",
                "&:hover": {
                  // bgcolor: "#0281C6",
                  color: "#EE7F1C",
                },
              }}
            >
              Don’t have an account? Sign Up
            </Typography>
            {/* </Link> */}
          </Grid>
        </Grid>

        <Grid mt={5}>
          <Typography
            // noWrap
            textAlign={"center"}
            sx={{
              color: "#EE7F1C",
              // cursor: "pointer",
              fontWeight: "500",
              // "&:hover": {
              //   // bgcolor: "#0281C6",
              //   color: "#EE7F1C",
              // },
            }}
          >
            Note: Old users need to contact the admin for your new password
          </Typography>
        </Grid>
      </Box>
      <ForgotPassword
        open={open}
        handleClose={handleClose}
        handleOpen={handleOpen}
      />
      <UnApprovedPopUp 
      open={openUnApp}
      handleClose={handleCloseUnApp}
      handleOpen={handleOpenUnApp}
      msg1= "You are currently unapproved. "
      msg2 = "You will be notified once it is approved"
      />
      
    </Box>
  );
};

export default Login;
