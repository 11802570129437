//React hooks and deeps import
import React, { useLayoutEffect } from "react";
import "./App.css";
import { Suspense, useEffect } from "react";
import {
  Routes,
  Route,
  useLocation,
  Navigate,
  useNavigate,
  Outlet,
} from "react-router-dom";

//Routes Import
import { ThemeProvider } from "@emotion/react";
import { theme } from "./constants/theme";
import OutletComponent from "./components/OutletComponent";
import "./assets/fonts/Moderat Font/Moderat-Regular.ttf";
import "./assets/fonts/Moderat Font/Moderat-Medium.ttf";
import "./assets/fonts/Moderat Font/Moderat-Bold.ttf";
import {
  DashboardRouteList,
  ProtectedRouteList,
  UnProtectedRouteList,
  UnprotectedRouteList,
  routesList,
} from "./routes/routes";
import { HideOnScroll, Navbar } from "./components/Navbar";
import { useDispatch, useSelector } from "react-redux";
import { login } from "./store/auth.slice";
import Login from "./routes/Login/Login";
import Home from "./routes/Home/Home";
import PageNotFound from "./components/PageNotFound";
import { useQuery } from "@tanstack/react-query";
import { api } from "./api/api";
import whatsAppIcon from "./assets/whatsapp.svg";
// import whatsAppIcon from "./assets/icons/product/whatsapp.svg";
import { Helmet } from "react-helmet";

function App() {
  const location = useLocation();
  const navigate = useNavigate();
  const { auth } = useSelector((state) => state);
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    const isAuth = JSON.parse(localStorage.getItem("isAuthenticated"));
    if (isAuth) {
      let user = JSON.parse(localStorage.getItem("user"));
      if (!user) return;
      dispatch(login(user));
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const val = ProtectedRouteList.find(
    (dt) => dt[1].paths[0].split("/")[1] === location.pathname.split("/")[1]
  );

  const { data: cartSummary } = useQuery({
    queryKey: ["cartSummary"],
    queryFn: () => api.getCartSummary(auth.id),
    refetchOnWindowFocus: false,
    enabled: auth.isAuthenticated,
  });

  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <title>JagsWeb</title>
        <meta name="description" content="" />
      </Helmet>
      <AuthRoutes />
      {ProtectedRouteList.find(
        (dt) => dt[1].paths[0].split("/")[1] === location.pathname.split("/")[1]
      ) ? null : (
        <UnAuthRoutes />
      )}
      <a
        // href="https://wa.me/919029162738"
        href="https://wa.me/918850739998"
        class="whatsapp_float"
        target="_blank"
        rel="noopener noreferrer"
      >
        {/* <i class="fa fa-whatsapp whatsapp-icon"></i> */}
        <img src={whatsAppIcon} height={"100%"} alt="whatsApp" srcset="" />
      </a>
    </ThemeProvider>
  );
}

const UnAuthRoutes = ({ auth }) => {
  return (
    <Routes>
      <Route element={<OutletComponent />}>
        {UnProtectedRouteList.map((_route) => {
          const route = _route[1];
          const { key, paths, element, suspense, fallback } = route;
          return paths.map((_path) => {
            return (
              <Route
                key={key}
                path={_path}
                exact
                element={
                  suspense ? (
                    <Suspense {...{ fallback }}>{element}</Suspense>
                  ) : (
                    element
                  )
                }
              />
            );
          });
        })}
        {/* <Route path="*" Component={PageNotFound} /> */}
      </Route>
    </Routes>
  );
};

const AuthRoutes = ({ auth }) => {
  let location = useLocation();
  return (
    <Routes>
      <Route element={<OutletComponent />}>
        {ProtectedRouteList.map((_route) => {
          const route = _route[1];
          const { key, paths, element, suspense, fallback } = route;
          return paths.map((_path) => {
            return (
              <Route element={<ProtectedRoute />}>
                <Route
                  key={key}
                  path={_path}
                  exact
                  element={
                    suspense ? (
                      <Suspense {...{ fallback }}>{element}</Suspense>
                    ) : (
                      element
                    )
                  }
                />
              </Route>
            );
          });
        })}
        {/* <Route path="*" Component={PageNotFound} /> */}
      </Route>
    </Routes>
  );
};

const ProtectedRoute = () => {
  const { isAuthenticated } = useSelector((state) => state.auth);
  let location = useLocation();
  return isAuthenticated ? (
    <Outlet />
  ) : (
    <Navigate to={"/login"} state={{ from: location }} replace />
  );
};

export default App;
