import  {toast}  from "react-hot-toast";

const createPromiseToast = ({ success, message }) => {
    var successMessage = success ? message : "";
    var errorMessage = !success ? message : "";

    toast.promise(
      new Promise((resolve, reject) => {
        if (success) {
          resolve(console.log(successMessage));
        } else {
          reject(console.error(errorMessage));
        }
      }),
      {
        loading: "Loading",
        error: errorMessage,
        success: successMessage,
      },
      {
        style: {
          minWidth: "250px",
        },
        success: {
          duration: 3000,
        },
        error: {
          duration: 3000,
        },
      }
    );
  };
  
  export { createPromiseToast };