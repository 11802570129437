import React from "react";
import { Box, Typography, Skeleton } from "@mui/material";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useNavigate } from "react-router-dom";
import "react-lazy-load-image-component/src/effects/blur.css";

const CategoryCard = ({
  item,
  viewOnClick,
  color,
  cnt,
  goToProduct,
  noSubSubCategory,
  isSpecialEditon,
}) => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        backgroundColor: color,
        height: "100%",
        width: "100%",
        borderRadius: ".5rem",
        p: ".8rem",
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
        cursor: "pointer",
      }}
      onClick={() => {
        goToProduct
          ? navigate(
              `/${viewOnClick}-${item.CATEGORY}/${item.CCODE}-${item.SUBCATG}`
            )
          : noSubSubCategory
          ? navigate(
              `/newarrival/noSubSubCategory/${item.CCODE}-${item.SUBCATG}`
            )
          : navigate(`/${viewOnClick}/${item.CCODE}-${item.CATEGORY}`);
      }}
    >
      <Box
        sx={{
          // width: { lg: "12rem",xs:'37vw',md:'19vw' },
          aspectRatio: 1,
          overflow: "hidden",
          borderRadius: ".5rem",
        }}
      >
        <LazyLoadImage
          src={
            item ? item.PRD_IMAGE : "https://picsum.photos/seed/picsum/200/300"
          }
          effect="blur"
          width="100%"
          height="100%"
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: ".8rem",
          flexDirection: "column",
          gap: 1,
        }}
      >
        {isSpecialEditon && (
          <Typography
            sx={{
              color: "#9AA0A1",
              fontSize: ".9rem",
              flexGrow: 1,
              flexShrink: 0,
              width: "100%",
            }}
          >
            Special Edition
          </Typography>
        )}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            // marginTop: ".8rem",
            gap: 1,
          }}
        >
          <Typography
            sx={{
              color: "#465152",
              fontSize: {
                lg: cnt ? "1.4rem" : "1.1rem",
                xs: ".8rem",
                md: "1rem",
              },
              textAlign: "center",
              textTransform: "capitalize",
            }}
          >
            {item ? item.SUBCATG || item.CATEGORY : ""}{" "}
          </Typography>
          {cnt ? (
            <Typography
              sx={{
                backgroundColor: "#0281C6",
                paddingX: { lg: ".7rem", xs: ".5rem" },
                fontSize: { lg: "1rem", xs: ".6rem" },
                paddingY: { lg: "0rem" },
                borderRadius: 30,
                color: "#FFF",
                display: "inline-flex",
                placeItems: "center",
              }}
            >
              {item.CNT}
            </Typography>
          ) : (
            ""
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default CategoryCard;

export const SkeletonCategoryCard = ({ color, isSpecialEditon, cnt }) => (
  <Box
    sx={{
      backgroundColor: color,
      height: "100%",
      width: "100%",
      borderRadius: ".5rem",
      p: ".8rem",
      display: "flex",
      flexDirection: "column",
      textAlign: "center",
      cursor: "pointer",
    }}
  >
    <Box
      sx={{
        aspectRatio: 1,
        overflow: "hidden",
        borderRadius: ".5rem",
      }}
    >
      <Skeleton variant="rounded" width={"100%"} height={"100%"} />
    </Box>
    <Box
      sx={{
        // display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: ".8rem",
        flexDirection: "column",
        gap: 1,
      }}
    >
      {isSpecialEditon && <Skeleton variant="text" width={"100%"} />}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          // marginTop: ".8rem",
          gap: 1,
        }}
      >
        <Skeleton
          variant="text"
          width={"100%"}
          sx={{
            fontSize: {
              lg: cnt ? "1.4rem" : "1.1rem",
            },
          }}
        />
        {cnt ? (
          <Skeleton
            width={"20%"}
            variant="text"
            sx={{
              fontSize: { lg: "1rem", xs: ".6rem" },
            }}
          ></Skeleton>
        ) : (
          ""
        )}
      </Box>
    </Box>
  </Box>
);
