import React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import { Typography, Button, useMediaQuery, useTheme } from "@mui/material";
import EastIcon from "@mui/icons-material/East";
import corporateGiftingImg from "../../assets/images/home/corporate-gifting.jpg";
import office from "../../assets/images/home/office-stationery.png";
import writinginstruments from "../../assets/images/home/WritingInstruments.png";
import artandcraft from "../../assets/images/home/Art&Craft.png";
import special from "../../assets/images/home/special-edition.png";
import { useNavigate } from "react-router-dom";
import Carousel from "react-multi-carousel";
import CustomArrows from "../carousels/CustomArrows";

const HomeBanner = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const responsive = {
    mobile: {
      breakpoint: { max: 767, min: 0 },
      items: 1,
      // paritialVisibilityGutter: 30,
    },
  };

  // const carousleData = [
  //   {
  //     id: 1,
  //     title: "Writing Instruments",
  //     link: "2984-Corporate & Office Stationery/1468-Writing Instruments",
  //   },
  //   {
  //     id: 2,
  //     title: "Art & Craft",
  //     link: "2983-Art & Craft",
  //   },
  //   {
  //     id: 3,
  //     title: "Corporate Gifting",
  //     link: "2984-Corporate & Office Stationery",
  //   },
  //   {
  //     id: 4,
  //     title: "Pen Stand",
  //     link: "2984-Corporate & Office Stationery/1462-Pen Stand",
  //   },
  // ];

  return !isSmallScreen ? (
    <Box sx={{ width: "100%", mt: 1 }}>
      <Box
        sx={{
          gap: "1rem",
          width: "100%",
          justifyContent: "center",
          display: "flex",
          flexDirection: { lg: "row", md: "row", xs: "column" },
        }}
      >
        <Box
          sx={{
            minWidth: "34%",
            aspectRatio: !isSmallScreen ? "1" : "2/1",
            backgroundImage: `url(${artandcraft})`,
            backgroundSize: "100% 100%",
            py: { lg: "1.7rem", xs: "1rem" },
            px: { lg: "2rem", xs: "1rem" },
          }}
        >
          <Box>
            <Typography
              sx={{
                color: "#FFF",
                fontWeight: "500",
                fontSize: { lg: "1.8rem", md: "1.6rem", xs: "1.5rem" },
                mb: "1.5rem",
              }}
            >
              Art & Craft
            </Typography>
            <Button
              sx={{
                color: "#FFF",
                fontWeight: "500",
                fontSize: { lg: "1.1rem", xs: ".8rem" },
                textTransform: "none",
                border: ".1rem solid #FFF",
                borderRadius: 50,
                px: "1.3rem",
                "&:hover": {
                  backgroundColor: "#FFF",
                  color: "#343434",
                },
              }}
              endIcon={<EastIcon />}
              onClick={() => navigate("2983-Art & Craft")}
            >
              Shop Now
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            minWidth: "22%",
            aspectRatio: !isSmallScreen ? "1" : "2/1",
            backgroundImage: `url(${special})`,
            backgroundSize: "100% 100%",

            py: { lg: "1.7rem", xs: "1rem" },
            px: { lg: "2rem", xs: "1rem" },
          }}
        >
          <Box>
            <Typography
              sx={{
                color: "#FFF",
                fontWeight: "500",
                fontSize: { lg: "1.8rem", md: "1.6rem", xs: "1.5rem" },
                mb: "1.5rem",
              }}
            >
              Special Edition
            </Typography>
            <Button
              sx={{
                color: "#FFF",
                fontWeight: "500",
                fontSize: { lg: "1.1rem", xs: ".8rem" },
                textTransform: "none",
                border: ".1rem solid #FFF",
                borderRadius: 50,
                px: "1.3rem",
                "&:hover": {
                  backgroundColor: "#FFF",
                  color: "pink",
                },
              }}
              endIcon={<EastIcon />}
              onClick={() => navigate("4404-Special Edition")}
            >
              Shop Now
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            minWidth: "34%",
            aspectRatio: "1",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            gap: "1rem",
          }}
        >
          <Box
            sx={{
              width: "100%",
              backgroundImage: `url(${corporateGiftingImg})`,
              flex: 1,
              backgroundSize: "100% 100%",
              py: { lg: "1.7rem", xs: "1rem" },
              px: { lg: "2rem", xs: "1rem" },
            }}
          >
            <Box>
              <Typography
                sx={{
                  color: "#FFF",
                  fontWeight: "500",
                  fontSize: { lg: "1.8rem", md: "1.6rem", xs: "1.5rem" },
                  mb: "1.5rem",
                }}
              >
                Corporate Gifts and Stationery 365
              </Typography>
              <Button
                sx={{
                  color: "#FFF",
                  fontWeight: "500",
                  fontSize: { lg: "1.1rem", xs: ".8rem" },
                  textTransform: "none",
                  border: ".1rem solid #FFF",
                  borderRadius: 50,
                  px: "1.3rem",
                  "&:hover": {
                    backgroundColor: "#FFF",
                    color: "#9ebec9",
                  },
                }}
                endIcon={<EastIcon />}
                onClick={() => navigate("2984-Corporate & Office Stationery")}
              >
                Shop Now
              </Button>
            </Box>
          </Box>
          {/* <Box
            sx={{
              width: "100%",
              backgroundImage: `url(${office})`,
              flex: 1,
              backgroundSize: "100% 100%",
              py: { lg: "1.7rem", xs: "1rem" },
              px: { lg: "2rem", xs: "1rem" },
            }}
          >
            <Box>
              <Typography
                sx={{
                  color: "#FFF",
                  fontWeight: "500",
                  fontSize: { lg: "1.8rem", md: "1.6rem", xs: "1.5rem" },
                  mb: "1.5rem",
                }}
              >
                Office Stationery
              </Typography>
              <Button
                sx={{
                  color: "#FFF",
                  fontWeight: "500",
                  fontSize: { lg: "1.1rem", xs: ".8rem" },
                  textTransform: "none",
                  border: ".1rem solid #FFF",
                  borderRadius: 50,
                  px: "1.3rem",
                  "&:hover": {
                    backgroundColor: "#FFF",
                    color: "pink",
                  },
                }}
                endIcon={<EastIcon />}
                onClick={() =>
                  navigate(
                    "2984-Corporate & Office Stationery/1459-Office Product"
                  )
                }
              >
                Shop Now
              </Button>
            </Box>
          </Box> */}
        </Box>
      </Box>
    </Box>
  ) : (
    <Carousel
      showDots={false}
      responsive={responsive}
      infinite={true}
      autoPlay={true}
      autoPlaySpeed={2500}
      customLeftArrow={<CustomArrows direction="left" />}
      customRightArrow={<CustomArrows direction="right" />}
      // keyBoardControl={true}
      // customTransition="all 3"
      // transitionDuration={500}
      containerClass="carousel-container"
      removeArrowOnDeviceType={["tablet", "mobile"]}
      deviceType="desktop"
      dotListClass="custom-dot-list-style"
      itemClass="carousel-item-padding-40-px"
    >
      {/* {carousleData.map((item) => (
        <Box
          sx={{
            minWidth: "34%",
            aspectRatio: !isSmallScreen ? "1" : "2/1",
            backgroundImage: `url(${writinginstruments})`,
            backgroundSize: "100% 100%",
            py: { lg: "1.7rem", xs: "1rem" },
            px: { lg: "2rem", xs: "1rem" },
          }}
        >
          <Box>
            <Typography
              sx={{
                color: "#FFF",
                fontWeight: "500",
                fontSize: { lg: "1.8rem", md: "1.6rem", xs: "1.5rem" },
                mb: "1.5rem",
              }}
            >
              {item.title}
            </Typography>
            <Button
              sx={{
                color: "#FFF",
                fontWeight: "500",
                fontSize: { lg: "1.1rem", xs: ".8rem" },
                textTransform: "none",
                border: ".1rem solid #FFF",
                borderRadius: 50,
                px: "1.3rem",
                "&:hover": {
                  backgroundColor: "#FFF",
                  color: "#87c0c9",
                },
              }}
              endIcon={<EastIcon />}
              onClick={() =>
                navigate(
                  "2984-Corporate & Office Stationery/1468-Writing Instruments"
                )
              }
            >
              Shop Now
            </Button>
          </Box>
        </Box>
      ))} */}
      <Box
        sx={{
          minWidth: "34%",
          aspectRatio: !isSmallScreen ? "1" : "2/1",
          backgroundImage: `url(${artandcraft})`,
          backgroundSize: "100% 100%",
          py: { lg: "1.7rem", xs: "1rem" },
          px: { lg: "2rem", xs: "1rem" },
        }}
      >
        <Box>
          <Typography
            sx={{
              color: "#FFF",
              fontWeight: "500",
              fontSize: { lg: "1.8rem", md: "1.6rem", xs: "1.5rem" },
              mb: "1.5rem",
            }}
          >
            Art & Craft
          </Typography>
          <Button
            sx={{
              color: "#FFF",
              fontWeight: "500",
              fontSize: { lg: "1.1rem", xs: ".8rem" },
              textTransform: "none",
              border: ".1rem solid #FFF",
              borderRadius: 50,
              px: "1.3rem",
              "&:hover": {
                backgroundColor: "#FFF",
                color: "#87c0c9",
              },
            }}
            endIcon={<EastIcon />}
            onClick={() => navigate("2983-Art & Craft")}
          >
            Shop Now
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          minWidth: "22%",
          aspectRatio: !isSmallScreen ? "1" : "2/1",
          backgroundImage: `url(${special})`,
          backgroundSize: "100% 100%",

          py: { lg: "1.7rem", xs: "1rem" },
          px: { lg: "2rem", xs: "1rem" },
        }}
      >
        <Box>
          <Typography
            sx={{
              color: "#FFF",
              fontWeight: "500",
              fontSize: { lg: "1.8rem", md: "1.6rem", xs: "1.5rem" },
              mb: "1.5rem",
            }}
          >
            Special Edition
          </Typography>
          <Button
            sx={{
              color: "#FFF",
              fontWeight: "500",
              fontSize: { lg: "1.1rem", xs: ".8rem" },
              textTransform: "none",
              border: ".1rem solid #FFF",
              borderRadius: 50,
              px: "1.3rem",
              "&:hover": {
                backgroundColor: "#FFF",
                color: "pink",
              },
            }}
            endIcon={<EastIcon />}
            onClick={() => navigate("4404-Special Edition")}
          >
            Shop Now
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          backgroundImage: `url(${corporateGiftingImg})`,
          flex: 1,
          backgroundSize: "100% 100%",
          py: { lg: "1.7rem", xs: "1rem" },
          px: { lg: "2rem", xs: "1rem" },
        }}
      >
        <Box>
          <Typography
            sx={{
              color: "#FFF",
              fontWeight: "500",
              fontSize: { lg: "1.8rem", md: "1.6rem", xs: "1.5rem" },
              mb: "1.5rem",
            }}
          >
            Corporate Gifts and Stationery 365
          </Typography>
          <Button
            sx={{
              color: "#FFF",
              fontWeight: "500",
              fontSize: { lg: "1.1rem", xs: ".8rem" },
              textTransform: "none",
              border: ".1rem solid #FFF",
              borderRadius: 50,
              px: "1.3rem",
              "&:hover": {
                backgroundColor: "#FFF",
                color: "#9ebec9",
              },
            }}
            endIcon={<EastIcon />}
            onClick={() => navigate("2984-Corporate & Office Stationery")}
          >
            Shop Now
          </Button>
        </Box>
      </Box>
      {/* <Box
        sx={{
          width: "100%",
          height: "100%",
          backgroundImage: `url(${office})`,
          flex: 1,
          backgroundSize: "100% 100%",
          py: { lg: "1.7rem", xs: "1rem" },
          px: { lg: "2rem", xs: "1rem" },
        }}
      >
        <Box>
          <Typography
            sx={{
              color: "#FFF",
              fontWeight: "500",
              fontSize: { lg: "1.8rem", md: "1.6rem", xs: "1.5rem" },
              mb: "1.5rem",
            }}
          >
            Offic Stationery
          </Typography>
          <Button
            sx={{
              color: "#FFF",
              fontWeight: "500",
              fontSize: { lg: "1.1rem", xs: ".8rem" },
              textTransform: "none",
              border: ".1rem solid #FFF",
              borderRadius: 50,
              px: "1.3rem",
              "&:hover": {
                backgroundColor: "#FFF",
                color: "pink",
              },
            }}
            endIcon={<EastIcon />}
            onClick={() =>
              navigate("2984-Corporate & Office Stationery/1459-Office Product")
            }
          >
            Shop Now
          </Button>
        </Box>
      </Box> */}
    </Carousel>
  );
};

export default HomeBanner;
