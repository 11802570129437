import React, { useState,useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { DashboardRoute } from "../routes";
import { Button } from "@mui/material";
import HomeBanner from "../../components/home/HomeBanner";
import { Box, Typography } from "@mui/material";
import { CarouselComponent } from "../../components/carousels/CategoryCarousel";
import HomeCarousels from "../../components/home/HomeCarousels";
import RectangleCard from "../../components/home/GiftAndMore";
import GiftAndMore from "../../components/home/GiftAndMore";
import { api } from "../../api/api";
import { ccode } from "../../constants/ccode";
import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import {Helmet} from 'react-helmet'

const Home = () => {
  const navigate = useNavigate();

  const {auth}=useSelector(state=>state)

  useEffect(() => {
    // loadDataOfCategories(ccode.art_craft," ",setArtAndCraftData)
    // loadDataOfCategories(ccode.corporate," ",setCorporateData)
    // loadDataOfCategories(ccode.return_gift," ",setreturnGiftData)
    // loadnewArrivalData('U','N')
  }, [])
  

  // const [artAndCraftData, setArtAndCraftData] = useState([])
  // const [corporateData, setCorporateData] = useState([])
  // const [returnGiftData , setreturnGiftData ] = useState([])
  // const [newArivalData, setNewArivalData] = useState([])

  const { data: artAndCraftData} = useQuery({
    queryKey: ["artAndCraftData",],
    queryFn: () => api.getCategoriesData( ccode.art_craft,auth.id),
    staleTime:' Infinity',
    cacheTime:' Infinity',
    refetchOnMount:false,
    refetchOnWindowFocus:false,
  });
  const { data: corporateData} = useQuery({
    queryKey: ["corporateData",],
    queryFn: () => api.getCategoriesData( ccode.corporate,auth.id),
    staleTime:' Infinity',
    cacheTime:' Infinity',
    refetchOnMount:false,
    refetchOnWindowFocus:false,
  });
  const { data: returnGiftData} = useQuery({
    queryKey: ["returnGiftData",],
    queryFn: () => api.getCategoriesData( ccode.return_gift,auth.id),
    staleTime:' Infinity',
    cacheTime:' Infinity',
    refetchOnWindowFocus:false,
    refetchOnMount:false
  });
  const { data: newArivalData} = useQuery({
    queryKey: ["newArivalData",],
    queryFn: () => api.getDataFromDB('U','N',auth.id),
    staleTime:' Infinity',
    cacheTime:' Infinity',
    refetchOnWindowFocus:false,
    refetchOnMount:false
  });


  const loadDataOfCategories=async (ccode,unid,callback)=>{
    try {
      let {data} = await api.getCategoriesData(ccode,unid) 
      callback(data)
    } catch (error) {
      console.log(error)
    }
  }

  // const loadnewArrivalData= async (type,ntype)=>{
  //   try {
  //     let {data}= await api.getSpecialCategorieData(type,ntype)
  //     setNewArivalData(data)
  //   } catch (error) {
      
  //   }
  // }
  

  return (
    <Box sx={{mx:{lg:'5.5rem',xs:'.5rem',md:'1rem'}}}>
      <Helmet>
        <title>JagsWeb | Home</title>
        <meta name="description" content="Home page"/>
        <meta name="google-site-verification" content="o2kgFBxr5TT9U_e7U9UDig03RdGskZt0omYhfFXpg1s" />
      </Helmet>
      <HomeBanner />
      <HomeCarousels heading={"Art & Craft"} color={"#FFE8D4"} data={artAndCraftData?artAndCraftData.data:[]} categoryCcode={ccode.art_craft} />
      <HomeCarousels heading={"Corporate & Office Stationery"} color={"#D7F1FF"} data={corporateData?corporateData.data:[]} categoryCcode={ccode.corporate} />
      <GiftAndMore heading={"Return Gift and More"} data={returnGiftData?returnGiftData.data:[]} categoryCcode={ccode.return_gift}/> 
      <HomeCarousels heading={"New Arrivals"} color={"#FFE8D4"} data={newArivalData?newArivalData.data:[]} noSubSubCategory={true} />
    </Box>
  );
};

export default Home;
