import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Box } from "@mui/material";
import CategoryCard, { SkeletonCategoryCard } from "../cards/CategoryCard";

import CustomArrows from "./CustomArrows";
export const CategoryCarousel = ({ color, data,viewOnClick,noSubSubCategory }) => {

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1023 },
      items: 5,
      paritialVisibilityGutter: 60,
    },
    tablet: {
      breakpoint: { max: 1023, min: 767 },
      items: 4,
      paritialVisibilityGutter: 50,
    },
    mobile: {
      breakpoint: { max: 767, min: 0 },
      items: 2,
      paritialVisibilityGutter: 30,
    },
  };


  return (
    <Carousel
      //  swipeable={false}
      // draggable={false}
      showDots={false}
      responsive={responsive}
      // ssr={true} // means to render carousel on server-side.
      infinite={true}
      // autoPlay={true}
      autoPlaySpeed={2500}
      customLeftArrow={<CustomArrows direction="left" />}
      customRightArrow={<CustomArrows direction="right" />}
      // keyBoardControl={true}
      // customTransition="all .5"
      // transitionDuration={500}
      containerClass="carousel-container"
      removeArrowOnDeviceType={["tablet", "mobile"]}
      deviceType="desktop"
      dotListClass="custom-dot-list-style"
      itemClass="carousel-item-padding-40-px"
    >

      {data && data.length>0?data.map(item=><CategoryCard key={item.CCODE} item={item} color={color} viewOnClick={viewOnClick} noSubSubCategory={noSubSubCategory} ></CategoryCard>):[0,1,2,3,4].map(item=><SkeletonCategoryCard key={item} color={color} />)}
      {/* <SmallCard color={color} />
      <SmallCard color={color} />
      <SmallCard color={color} />
      <SmallCard color={color} />
      <SmallCard color={color} />
      <SmallCard color={color} />
      <SmallCard color={color} />
      <SmallCard color={color} /> */}
    </Carousel>
  );
};

