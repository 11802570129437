import React, { useEffect } from "react";
import { Link, MenuItem, Badge, Paper } from "@mui/material";
import { styled, alpha } from "@mui/material/styles";
import { Link as RouterLink, useLocation } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { InputAdornment, ListItemIcon, Menu } from "@mui/material";
import NavbarLinks from "./NavbarLInks";
import { useDispatch, useSelector } from "react-redux";
import routes from "../routes/routes";
import { useNavigate } from "react-router-dom";
import logo from "../assets/icons/navbar/logo.png";
import cart from "../assets/icons/navbar/cart.png";
import profile from "../assets/icons/navbar/profile.png";
import productsIcon from "../assets/icons/navbar/productsIcon.png";
import newArrivalIcon from "../assets/icons/navbar/newArrivalIcon.png";
import feedbackIcon from "../assets/icons/navbar/feedbackIcon.png";
import aboutUsIcon from "../assets/icons/navbar/aboutUsIcon.png";
import contactUsIcon from "../assets/icons/navbar/contactUsIcon.png";
import HomeIcon from "../assets/icons/navbar/HomeIcon.png";
import bulkOrderIcon from "../assets/icons/navbar/bulkOrder.png";
import LoginIconNav from "../assets/icons/navbar/login.png";
import CartLogo from "../assets/icons/navbar/Your_cart.png";
import Userlogo from "../assets/icons/navbar/User.png";
import cartHover from "../assets/icons/navbar/cartHover.png";
import {
  Button,
  CustomSearchField as SearchField,
} from "./CustomStyle/CustomStyle";
import Collapse from "@mui/material/Collapse";

import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
// import HomeIcon from "@mui/icons-material/Home";
import CategoryIcon from "@mui/icons-material/Category";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import InfoIcon from "@mui/icons-material/Info";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import LoginIcon from "@mui/icons-material/Login";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import FeedbackOutlinedIcon from "@mui/icons-material/FeedbackOutlined";
import { logOut } from "../store/auth.slice";
import { createPromiseToast } from "../utils/createPromiseToast";
import closeIcon from "../assets/icons/modal/close.png";
import { QueryCache, useQueryClient } from "@tanstack/react-query";

const drawerWidth = 280;
const navItems = [
  {
    name: "Art & Craft",
    path: "/2983-Art & Craft",
  },
  {
    name: "Corporate & Office Stationery",
    path: "/2984-Corporate & Office Stationery",
  },
  {
    name: "Return Gift & More",
    path: "/2985-Return Gift & More",
  },
  {
    name: "Special Edition",
    path: "/4404-Special Edition",
  },
];
const navItems1 = [
  {
    name: "Profile",
    path: "/profile",
  },
  {
    name: "Booking Summary",
    path: "/bookingsummary",
  },
  {
    name: "Track Your Order",
    path: "/trackorders",
  },
  {
    name: "Custom Order",
    path: "/custom-order",
  },
  {
    name: "Store Locator",
    path: "/storelocator",
  },
];

const dropDownSX = {
  textDecoration: "none",
  fontWeight: "400",
  fontSize: "16px",
  textAlign: "right",
  width: "100%",
};
const menuItemSX = { py: ".35rem", px: ".9rem" };

// function HideOnScroll(props) {
//   const { children, window } = props;
//   // Note that you normally won't need to set the window ref as useScrollTrigger
//   // will default to window.
//   // This is only being set here because the demo is in an iframe.
//   const trigger = useScrollTrigger({
//     target: window ? window() : undefined,
//   });

//   return (
//     <Slide appear={false} direction="down" in={!trigger}>
//       {children}
//     </Slide>
//   );
// }

function DrawerAppBar(props) {
  const { window } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { isAuthenticated, name , user} = useSelector((state) => state.auth);

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isHovered, setIsHovered] = React.useState(false);
  const [searchText, setSearchText] = React.useState("");
  const [openSubMenu, setOpenSubMenu] = React.useState(false);
  const [openUserSubMenu, setOpenUserSubMenu] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl1, setAnchorEl1] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const handleDropdownClose = () => {
    setAnchorEl(null);
    setAnchorEl1(!anchorEl1);
  };
  const handleDropdownClick = (event) => {
    setAnchorEl(event.currentTarget);
    setAnchorEl1(!anchorEl1);
  };

  const queryClient = useQueryClient();
  const cartSummary = queryClient.getQueryData(["cartSummary"]);
  console.log(user,"user details");
  const drawer = (
    <Box
      onClick={handleDrawerToggle}
      sx={{ textAlign: "center", height: "85%" }}
    >
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        height={"4rem"}
      >
        <Typography variant="h6" px={2}>
          JAGS India
        </Typography>
        <Box
          sx={{
            position: "absolute",
            right: 17,
            // top: 17,
            cursor: "pointer",
          }}
        // onClick={handleClose}
        >
          <img src={closeIcon} alt="" height={40} />
        </Box>
      </Box>
      <Divider />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          // justifyContent: "space-between",
          height: "100%",
        }}
      >
        <List sx={{ padding: "0 10px" }}>
          {isAuthenticated ? (
            <>
              <ListItem disablePadding>
                <ListItemButton
                  sx={{ textAlign: "left" }}
                  onClick={() => {
                    setOpenUserSubMenu(!openUserSubMenu);
                    setMobileOpen((prevState) => !prevState);
                  }}
                >
                  {/* <ListItemIcon>
                    <img
                      src={profile}
                      alt=""
                      width={20}
                      // style={{ marginRight: "15px" }}
                    />
                  </ListItemIcon> */}
                  <ListItemText
                    primary={
                      <Typography variant="h6" style={{ fontWeight: "bold" }}>
                        {name}
                      </Typography>
                    }
                  />
                  {openUserSubMenu ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
              </ListItem>
              <Collapse in={openUserSubMenu} timeout="auto" unmountOnExit>
                <List sx={{ padding: "0 15px" }}>
                  {navItems1.map((item) => (
                    <ListItem key={item.name} disablePadding>
                      <ListItemButton
                        sx={{ textAlign: "left" }}
                        onClick={() => {
                          navigate(item.path);
                          setOpenSubMenu(!openSubMenu);
                        }}
                      >
                        <ListItemIcon>
                          <img
                            src={LoginIcon}
                            alt=""
                            width={20}
                          // style={{ marginRight: "15px" }}
                          />
                        </ListItemIcon>
                        <ListItemText primary={item.name} />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              </Collapse>
            </>
          ) : null}
          <ListItem disablePadding>
            <ListItemButton
              sx={{ textAlign: "left" }}
              onClick={() => navigate("/")}
            >
              <ListItemIcon>
                <img
                  src={HomeIcon}
                  alt=""
                  width={20}
                // style={{ marginRight: "15px" }}
                />
              </ListItemIcon>
              <ListItemText primary={"Home"} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              sx={{ textAlign: "left" }}
              onClick={() => {
                setOpenSubMenu(!openSubMenu);
                setMobileOpen((prevState) => !prevState);
              }}
            >
              <ListItemIcon>
                <img
                  src={productsIcon}
                  alt=""
                  width={24}
                // style={{ marginRight: "15px" }}
                />
              </ListItemIcon>
              <ListItemText primary={"Product"} />
              {openSubMenu ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
          </ListItem>
          <Collapse in={openSubMenu} timeout="auto" unmountOnExit>
            <List sx={{ padding: "0 15px" }}>
              {navItems.map((item) => (
                <ListItem key={item.name} disablePadding>
                  <ListItemButton
                    sx={{ textAlign: "left" }}
                    onClick={() => {
                      navigate(item.path);
                      setOpenSubMenu(!openSubMenu);
                    }}
                  >
                    <ListItemText primary={item.name} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Collapse>
          <ListItem disablePadding>
            <ListItemButton
              sx={{ textAlign: "left" }}
              onClick={() => navigate("/newarrival")}
            >
              <ListItemIcon>
                <img
                  src={newArrivalIcon}
                  alt=""
                  width={24}
                // style={{ marginRight: "15px" }}
                />
              </ListItemIcon>
              <ListItemText primary={"New Arrival"} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              sx={{ textAlign: "left" }}
              onClick={() => navigate("/about")}
            >
              <ListItemIcon>
                <img
                  src={aboutUsIcon}
                  alt=""
                  width={27}
                // style={{ marginRight: "15px" }}
                />
              </ListItemIcon>
              <ListItemText primary={"About Us"} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              sx={{ textAlign: "left" }}
              onClick={() => navigate("/contact")}
            >
              <ListItemIcon>
                <img
                  src={contactUsIcon}
                  alt=""
                  width={25}
                // style={{ marginRight: "15px" }}
                />
              </ListItemIcon>
              <ListItemText primary={"Contact Us"} />
            </ListItemButton>
          </ListItem>
          {isAuthenticated &&
          <ListItem disablePadding>
            <ListItemButton
              sx={{ textAlign: "left" }}
              onClick={() => navigate("/bulk-order")}
            >
              <ListItemIcon>
                <img
                  src={bulkOrderIcon}
                  alt=""
                  width={25}
                // style={{ marginRight: "15px" }}
                />
              </ListItemIcon>
              <ListItemText primary={"Bulk Order"} />
            </ListItemButton>
          </ListItem>}
        </List>
        <List sx={{ padding: "0 10px" }}>
          {isAuthenticated ? null : (
            <>
              <ListItem disablePadding>
                <ListItemButton
                  sx={{ textAlign: "left" }}
                  onClick={() =>
                    navigate("/login", { state: { from: location } })
                  }
                >
                  <ListItemIcon>
                    <img
                      src={LoginIconNav}
                      alt=""
                      width={24}
                    // style={{ marginRight: "15px" }}
                    />
                  </ListItemIcon>
                  <ListItemText primary={"Login"} />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton
                  sx={{ textAlign: "left" }}
                  onClick={() => navigate("/signup")}
                >
                  <ListItemIcon sx={{ color: "#0281c6" }}>
                    <ExitToAppIcon />
                  </ListItemIcon>
                  <ListItemText primary={"Sign up"} />
                </ListItemButton>
              </ListItem>
            </>
          )}

          <ListItem disablePadding sx={{ display: { sm: "none" } }}>
            <ListItemButton
              sx={{ textAlign: "left" }}
              onClick={() => navigate("/feedback")}
            >
              <ListItemIcon>
                <img
                  src={feedbackIcon}
                  alt=""
                  width={20}
                // style={{ marginRight: "15px" }}
                />
              </ListItemIcon>
              <ListItemText primary={"Feedback"} />
            </ListItemButton>
          </ListItem>
          {isAuthenticated ? (
            <ListItem disablePadding>
              <ListItemButton
                sx={{ textAlign: "left" }}
                onClick={() => {
                  queryClient.removeQueries({
                    queryKey: ["cartSummary"],
                  });
                  createPromiseToast({
                    success: true,
                    message: "Logged out successfully",
                  });
                  dispatch(logOut());
                  navigate("/");
                }}
              >
                <ListItemIcon>
                  <img
                    src={LoginIconNav}
                    alt=""
                    width={20}
                  // style={{ marginRight: "15px" }}
                  />
                </ListItemIcon>
                <ListItemText primary={"Logout"} />
              </ListItemButton>
            </ListItem>
          ) : null}
        </List>
      </Box>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box>
      <AppBar
        position="static"
        sx={{
          bgcolor: "#0281C6",
          height: "7dvh",
          justifyContent: "center",
          boxShadow: "none",
          padding: "0 40px",
        }}
      >
        <Toolbar sx={{ justifyContent: "space-between" }}>
          <Typography
            noWrap
            component="div"
            sx={{
              display: { xs: "block", sm: "block" },
              marginRight: { lg: 10, sm: 5 },
            }}
          >
            <a
              href="mailto:info@jagsindia.com"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              Mail us: info@jagsindia.com
            </a>{" "}
          </Typography>
          <Typography
            onClick={() => navigate(routes.feedback.paths[0])}
            noWrap
            component="div"
            sx={{
              display: { xs: "none", sm: "block", cursor: "pointer" },
            }}
          >
            Feedback
          </Typography>
        </Toolbar>
      </AppBar>

      <AppBar
        component="nav"
        position="static"
        sx={{
          bgcolor: "#F3F6F9",
          height: { xs: "15vh", sm: "20vh" },
          justifyContent: "center",
          boxShadow: "none",
          padding: { xs: "10px", lg: "0 40px" },
        }}
      >
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
            paddingX: { xs: 0, sm: 1 },
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <IconButton
              size="large"
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: { sm: 2 }, display: { lg: "none" }, color: "black" }}
            >
              <MenuIcon fontSize="50px" />
            </IconButton>
            <Box
              sx={{
                display: { sm: "block" },
                width: { xs: 85, sm: 120, md: 140, lg: 160 },
                cursor: "pointer",
              }}
              onClick={() => navigate(routes.home.paths[0])}
            >
              <img src={logo} alt="" width={"100%"} />
            </Box>
          </Box>
          <Search>
            <Paper
              component={"form"}
              sx={{
                borderRadius: 20,
                border: "1px solid #e3e3e3",
                pl: 2,
                boxShadow: "none",
                mr: { sm: 5 },
              }}
              onSubmit={(e) => {
                e.preventDefault();
                if (searchText.trim()) {
                  navigate(`/search/${searchText.trim()}`);
                  setSearchText('')
                }
              }}
            >
              <SearchField
                id="search"
                type="search"
                placeholder="Search..."
                onChange={(e) => setSearchText(e.target.value)}
                value={searchText}
                sx={{
                  width: "100%",
                  borderRadius: 50,
                  bgcolor: "#fff",
                  outline: "none",
                  border: "none",
                  padding: "0 10px",
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      sx={{ cursor: "pointer" }}
                    // onSubmit={() => {
                    //   if (searchText.trim()) {
                    //     navigate(`/search/${searchText.trim()}`);
                    //   }
                    // }}
                    >
                      <IconButton type="submit">
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Paper>
          </Search>
          <Box
            sx={{
              display: { xs: "none", lg: "flex" },
              height: "100%",
              alignItems: "center",
            }}
          >
            {isAuthenticated ? (
              <>
                <Button
                  variant="outlined"
                  // onClick={() => navigate(routes.login.paths[0])}
                  onClick={handleDropdownClick}
                  sx={{
                    marginRight: 2,
                    height: "80%",
                    // width: "20ch",
                    display: "flex",
                    justifyContent: "start",
                    background: "#D9E7F5",
                    "&:hover": {
                      background: "#D9E7F5",
                      color: "#465152",
                      border: "none",
                    },
                  }}
                >
                  <img
                    src={profile}
                    alt=""
                    width={23}
                    style={{ marginRight: "15px" }}
                  />{" "}
                  <span
                    style={{
                      color: "#465152",
                      display: "flex ",
                      justifyContent: "space-between",
                      alignItems: "center",
                      textAlign: "center",
                    }}
                  >
                    {" "}
                    {name} {anchorEl1 ? <ExpandLess /> : <ExpandMore />}
                  </span>
                </Button>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleDropdownClose}
                  sx={{ mt: ".4rem", minWidth: "25ch" }}
                  elevation={0}
                >
                  <MenuItem onClick={handleDropdownClose} sx={menuItemSX}>
                    <Link
                      component={RouterLink}
                      to="/profile"
                      color="inherit"
                      sx={dropDownSX}
                    >
                      Profile
                    </Link>
                  </MenuItem>
                  <MenuItem onClick={handleDropdownClose} sx={menuItemSX}>
                    <Link
                      component={RouterLink}
                      to="/bookingsummary"
                      color="inherit"
                      sx={dropDownSX}
                    >
                      Booking Summary
                    </Link>
                  </MenuItem>
                  <MenuItem onClick={handleDropdownClose} sx={menuItemSX}>
                    <Link
                      component={RouterLink}
                      to="/trackorders"
                      color="inherit"
                      sx={dropDownSX}
                    >
                      Track your order
                    </Link>
                  </MenuItem>
                  <MenuItem onClick={handleDropdownClose} sx={menuItemSX}>
                    <Link
                      component={RouterLink}
                      to="/custom-order"
                      color="inherit"
                      sx={dropDownSX}
                    >
                      Custom Order
                    </Link>
                  </MenuItem>
                  <MenuItem onClick={handleDropdownClose} sx={menuItemSX}>
                    <Link
                      component={RouterLink}
                      to="/storelocator"
                      color="inherit"
                      sx={dropDownSX}
                    >
                      Store Locator
                    </Link>
                  </MenuItem>
                  <MenuItem onClick={handleDropdownClose} sx={menuItemSX}>
                    <Link
                      to="/"
                      component={RouterLink}
                      onClick={() => {
                        queryClient.removeQueries({
                          queryKey: ["cartSummary"],
                        });
                        dispatch(logOut());
                        navigate("/");
                        createPromiseToast({
                          success: true,
                          message: "Logged out successfully",
                        });
                      }}
                      color="inherit"
                      sx={dropDownSX}
                    >
                      Log out
                    </Link>
                  </MenuItem>
                </Menu>
              </>
            ) : (
              <>
                <Button
                  variant="outlined"
                  onClick={() =>
                    navigate(routes.login.paths[0], {
                      state: { from: location },
                    })
                  }
                  sx={{
                    marginRight: 2,
                    height: "80%",
                    width: "15ch",
                  }}
                >
                  Login
                </Button>
                <Button
                  onClick={() => navigate(routes.signup.paths[0])}
                  variant="outlined"
                  sx={{
                    marginRight: 2,
                    height: "80%",
                    width: "15ch",
                  }}
                >
                  Sign Up
                </Button>
              </>
            )}
            <IconButton
              size="large"
              edge="end"
              aria-haspopup="true"
              onClick={() => {
                if (isAuthenticated) {
                  navigate(routes.cart.paths[0]);
                } else {
                  navigate(routes.login.paths[0]);
                }
              }}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
              sx={{
                bgcolor: "#D9E7F5",
                // height: "80%",
                // width:'15ch',
                "&:hover": {
                  background: "#0281C6",
                },
              }}
            >
              <Badge
                sx={{ top: "" }}
                badgeContent={
                  cartSummary && cartSummary.data
                    ? cartSummary.data[0] && cartSummary.data[0].CNT
                    : 0
                }
                color="primary"
              >
                <img src={isHovered ? cartHover : cart} alt="" width={23} />
              </Badge>
            </IconButton>
            {/* </Badge> */}
          </Box>
          <Box
            sx={{
              display: { xs: "flex", lg: "none" },
              height: "100%",
              alignItems: "center",
              // display:'flex',
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <IconButton
              size="large"
              edge="end"
              aria-haspopup="true"
              onClick={() => {
                if (isAuthenticated) {
                  navigate(routes.cart.paths[0]);
                } else {
                  navigate(routes.cart.paths[0]);
                }
              }}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
              sx={{
                bgcolor: "#D9E7F5",
                // height: "80%",
                // width:'15ch',
                "&:hover": {
                  background: "#0281C6",
                },
              }}
            >
              <Badge
                sx={{ top: "" }}
                badgeContent={
                  cartSummary && cartSummary.data
                    ? cartSummary.data[0] && cartSummary.data[0].CNT
                    : 0
                }
                color="primary"
              >
                <img src={isHovered ? cartHover : cart} alt="" width={20} />
              </Badge>
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      {/* </HideOnScroll> */}
      <NavbarLinks />
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", lg: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: { xs: "100%", sm: drawerWidth },
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
    </Box>
    // </Box>
  );
}

DrawerAppBar.propTypes = {
  window: PropTypes.func,
};

export default DrawerAppBar;

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  // marginRight: theme.spacing(2),
  marginLeft: theme.spacing(2),
  width: "100%",
  transform: "scale(.85)",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
    transform: "scale(1)",
  },
  [theme.breakpoints.up("md")]: {
    width: "50ch",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));
