import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  products: [],
  current: null,
  prev: null,
  next: null,
};


export const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    setProduct:(state,action)=>{
        state.products=action.payload
    },
    setCurrentProduct:(state,action)=>{
        state.current=action.payload
        if(state.products.length!=0){
          let currentIndex=state.products.indexOf(action.payload)
          state.prev=state.products[currentIndex-1]
          state.next=state.products[currentIndex+1]
        }
    },
    setPrevProduct:(state,action)=>{
        state.prev=action.payload
    },
    setNextProduct:(state,action)=>{
        state.next=action.payload
    }
  },
});

// Action creators are generated for each case reducer function
export const {setCurrentProduct,setNextProduct,setPrevProduct,setProduct} = productSlice.actions;

export default productSlice.reducer;
