import { createTheme } from "@mui/material";

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 768, // Change to 768px
      lg: 1200, // Change to 1024px
      xl: 1920,
    },
  },
  palette: {
    primary: {
      main: "#0281C6",
      light: "#D9E7F5",
    },
    secondary: {
      main: "#FFF",
    },
    text: {
      primary: "#465152",
      secondary: "#0281C6",
    },
  }
 
});
