import React from "react";
import { Box, Typography,Skeleton } from "@mui/material";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useNavigate } from "react-router-dom";
import 'react-lazy-load-image-component/src/effects/blur.css';


const GiftCard = ({ item, viewOnClick, i }) => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        backgroundColor: i % 2 === 0 ? "#FFE8D4" : "#D7F1FF",
        borderRadius: ".5rem",
        margin: "auto auto",
        p: ".5rem",
        width: "100%",
        display: "flex",
        alignItems: "center",
        whiteSpace: "nowrap",
        textAlign: "center",
        cursor:'pointer'
      }}
      onClick={() =>
        navigate(`/${viewOnClick}/${item.CCODE}-${item.CATEGORY}`)
      }
    >
      <Box
        sx={{
          width: { lg: "9.5rem", xs: "37vw", md: "9.5rem" },
          height: { lg: "9.5rem", xs: "37vw", md: "9.5rem" },

          overflow: "hidden",
          borderRadius: ".5rem",
        }}
      >
        <LazyLoadImage
          src={
            item ? item.PRD_IMAGE : "https://picsum.photos/seed/picsum/200/300"
          }
          effect="blur"
          width="100%"
          height="100%"
        />
      </Box>
      <Typography
        sx={{
          color: "#465152",
          flex: 1,
          alignSelf: "center",
          fontWeight: "500",
          fontSize: { lg: "1.9rem", xs: ".9rem", md: "1.2rem" },
        }}
      >
        {item ? item.CATEGORY : ""}
      </Typography>
    </Box>
  );
};

export default GiftCard;

export const SkeletonGiftCard=({i})=>  <Box
sx={{
  backgroundColor: i % 2 === 0 ? "#FFE8D4" : "#D7F1FF",
  borderRadius: ".5rem",
  margin: "auto auto",
  p: ".5rem",
  width: "100%",
  display: "flex",
  alignItems: "center",
  gap:'.5rem',
  whiteSpace: "nowrap",
  textAlign: "center",
  cursor:'pointer'
}}
>
<Box
  sx={{
    width: { lg: "9.5rem", xs: "37vw", md: "9.5rem" },
    height: { lg: "9.5rem", xs: "37vw", md: "9.5rem" },
    overflow: "hidden",
    borderRadius: ".5rem",
  }}
>
  <Skeleton variant="rounded" width={'100%'} height={'100%'} />
</Box>
<Skeleton variant="text"   sx={{
  flex:1,
  fontSize: { lg: "1.9rem", xs: ".9rem", md: "1.2rem" },
  }}/>
</Box>
