import React from "react";
import { Box, Typography, Button } from "@mui/material";
import SmallCard from "../cards/CategoryCard";
import { CarouselComponent, CategoryCarousel } from "../carousels/CategoryCarousel";
import EastIcon from "@mui/icons-material/East";
import { useNavigate } from "react-router-dom";
const HomeCarousels = ({ heading, data, color,categoryCcode,noSubSubCategory }) => {

  const navigate=useNavigate()
  

  return (
    <Box sx={{ my: { lg: "4rem", xs: "1.5rem",md:'2rem' }, mx: { lg: "3rem" } ,position:'relative'}}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          textAlign: "center",
          alignItems: "center",
          mb: { lg: "2rem", xs: ".5rem",md:'1rem' },
        }}
      >
        <Typography
          variant="h4"
          sx={{
            fontWeight: "700",
            fontSize: { lg: "2.3rem", xs: "1.3rem",md:'1.9rem' },
            color: "#465152",
            maxWidth: "65%",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {heading}
        </Typography>
        <Button
          sx={{
            color: "#FFF",
            fontWeight: "500",
            height: "min-content",
            whiteSpace: "nowrap",
            fontSize: { lg: "1.1rem", xs: ".7rem",md:'1rem' },
            textTransform: "none",
            border: ".1rem solid #FFF",
            borderRadius: 50,
            backgroundColor: "#0281C6",
            px: { lg: "1.3rem", xs: ".7rem" ,md:'1rem'},
            "&:hover": {
              backgroundColor: "#D7F1FF",
              color: "#0281C6",
            },
          }}
          endIcon={<EastIcon />}
          onClick={()=>{navigate(categoryCcode?`/${categoryCcode}-${data[0].MCATG}`:'newarrival')}}
        >
          View All
        </Button>
      </Box>
      {/* <SmallCard/> */}
      <CategoryCarousel color={color} data={data} viewOnClick={data[0]?categoryCcode+"-"+data[0].MCATG || data[0].SUBCATG:''} noSubSubCategory={noSubSubCategory} />
    </Box>
  );
};

export default HomeCarousels;
